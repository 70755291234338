import React from "react";
import styled from "styled-components";

import Projects from "../../../assets/projects.json";

const Container = styled.div`
    width: 100%;
    min-height: 200px;
    margin-top: 100px;
`;

const GridContainer = styled.div`
    display: table;
    width: 100%;
`;

const Grid = styled.div`
    width: 33.33%;
    float: left;
    padding: 10px;
    margin-bottom: 15px;
    
    @media (max-width: 768px) {
        width: 50%;
    }

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const ImageContainer = styled.img`
    width: 30%;
    height: 80px;
    background-color: #ededed;
    float:left;
    object-fit: cover;
    border-radius: 12px;
`;

const Content = styled.div`
    width: 70%;
    float: left;
    padding: 0px 10px;

    h4 {
        font-weight: 700;
        font-size: 16px;
        margin: 0px;
    }

    p {
        font-weight: 300;
        font-size: 12px;
        margin: 0px;
    }
`;

export default () => {
    return(
        <Container>
            <h3>More Projects</h3>
            <GridContainer>
                {
                    Projects.old.map((item, key)=> {
                        return (
                            <Grid key={key+item.name}>
                                <ImageContainer src={item.image} />
                                <Content>
                                    <h4>{item.name}</h4>
                                    <p>{item.platform}</p>
                                </Content>
                            </Grid>
                        )
                    })
                }
            </GridContainer>
        </Container>
    );    
}