import React from "react";
import styled from "styled-components"; 
import { motion } from "framer-motion";

const LinesContainer = styled.div`
    position: fixed;
    width: 100%;
    top: 0px;
    left:0px;
    height:100%;
    z-index: -5;
`;

const Lines = styled(motion.div)`
    border-right: 1px solid #d3d3d3;
    width: 16.666%;
    float:left;
    height:0%;
    min-height:0%;

    @media (max-width: 992px) {
        &:nth-child(1) {
            display: none;
        }

        &:nth-child(2), &:nth-child(5) {
            width: 8.333333333333333%;
        }

        &:nth-child(3), &:nth-child(4) {
            width: 41.66666666666667%;
        }
    }
`;

const Grid = () => {
    return (
        <LinesContainer>
            <Lines animate={{ minHeight: "100%", height: "100%" }} transition={{ duration: 1.5, ease: "easeOut"}} />
            <Lines animate={{ minHeight: "100%", height: "100%" }} transition={{ duration: 1.5, delay: 3.8, ease: "easeOut" }} />
            <Lines animate={{ minHeight: "100%", height: "100%" }} transition={{ duration: 1.5, delay: 4.1, ease: "easeOut" }} />
            <Lines animate={{ minHeight: "100%", height: "100%" }} transition={{ duration: 1.5, delay: 4.4, ease: "easeOut" }} />
            <Lines animate={{ minHeight: "100%", height: "100%" }} transition={{ duration: 1.5, delay: 4.7, ease: "easeOut" }} />
        </LinesContainer>
    );
}

export default Grid;