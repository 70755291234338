import React from "react";
import styled from "styled-components";

import { SmallHeading } from "../../../common/typography/Text";

const Container = styled.div`
    padding: 5px 20px 5px 0px;
`;

const Number = styled(SmallHeading)`
    && {
        margin: 0px;
        color: ${props => props.color};
    }
`;

const Stats = styled.div`
    width: 25%;
    float: left;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    display: block;
    margin: auto;
    padding: 60px 0px;
    
    @media (max-width: 768px) {
        width: 50%;
    }

    @media (max-width: 576px) {
        width: 100%;
    }
`;

export default ({numberColor, stats}) => {
    return (
        <Container>
            {stats.map((item, index) => {
                return (
                    <Stats key={item.stats+index}>
                        <Number color={numberColor}>{item.stats}</Number>
                        <p>{item.name}</p>
                    </Stats>
                )
            })}
            
        </Container>
    );
}