import { Clear, Switch, NameGuide, Flush } from "./Utility";

const handleFunctions = (command, t = null) => {
    const trimedCommand = command.split(" ")[0]; // this check only the first command and disregard all the options on the command
    switch (trimedCommand) {
        case "clear":
                Clear(t);
            break;
        
        case "switch":
                Switch(t, command);
            break;

        case "sayedrey":
                NameGuide();
            break;

        case "flushsession":
            Flush();
            break;
    
        default:
            break;
    }

}

export default handleFunctions;