import React from "react";
import styled from "styled-components";

const BImage = styled.div`
    width: 100%;
    height: auto;
    background-color: #ededed;

    img {
        width: 100%;
        object-fit: cover;
    }
`;

const BigImage = ({source}) => {
    return (
        <BImage>
            <img src={source} />
        </BImage>
    );
}

export { BigImage };