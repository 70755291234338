import React from "react";
import styled from "styled-components";

const RoleContainer = styled.div`
    padding: 20px 0px;
`;

const ItemContainer = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px;
`;

const Item = styled.li`
    display: inline-block;
    color: #585858;

    &:not(:last-child)::after {
        content: ">";
        padding: 10px;
    }
`;

export default ({roles}) => {
    return (
        <RoleContainer>
            <ItemContainer>
                {roles.map((item, index) => {
                    return <Item key={item+index}>{item}</Item>
                })}
            </ItemContainer>
        </RoleContainer>
    );
}