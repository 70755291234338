import React, { useState } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Dots } from "../../gui/common/Dots";

const GuiContent = styled.div`
    color: #ededed;
    width: 100vw;
    z-index: 1;
    height: 100%;
    float: right;
    position: absolute;
    z-index: 0;
`;

const IntroText = styled.div`
    width: 25%;
    padding: 50px;
    float: right;
    @media (max-width: 1024px) {
        padding: 20px;
    }

    
    @media (max-width: 768px) {
        width: 100%;
        position: relative;
        z-index: 5;
    }
`;

const MainText = styled.h2`
    font-size: 120px;
    font-weight: 700;
    color: #1042FF;
    letter-spacing: 20px;
    margin: 0px;

    @media (max-width: 1024px) {
        font-size: 80px;
        letter-spacing: 10px;
    }
`;

const SubText = styled.h3`
    font-weight: 500;
    margin: 0px;
    font-size: 18px;
    color: #3a3a3a;
    line-height: 30px;

    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 24px;
    }
`;

const StrokeText = styled.span`
    color: black;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: black;
    font-size: 300px;
    font-weight: 700;
    letter-spacing: 80px;
    position: absolute;
    bottom: 0;
    z-index: -6;
    opacity: 0.03;
    line-height: 1;
    cursor: default;
    text-align: right;
    width: 100%;
    
    @media (max-width: 768px) {
        top: 0;

        .big-el {
            display:none;
        }
    }
`;

const BigEl = styled.img`
    @media (max-width: 768px) {
        display:none;
    }
`;

const Proceed = styled.button`
    position: absolute;
    bottom: 50px;
    right: 50px;
    background-color: transparent;
    border: 1px solid #3a3a3a;
    padding: 15px 30px;
    color: #3a3a3a;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.5s;

    &&:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1024px) {
        bottom: 50px;
        left: 20px;
    }

    @media (max-width: 768px) {
        position: relative;
        bottom: 0px;
    }
`;

const GuiContentComponent = ({handleClick}) => {
    return(
        <GuiContent>
            <img src="/images/x.svg" style={{position: "absolute", right: "50px"}} />
            <img src="/images/x.svg" style={{position: "absolute", bottom: "50px", left: "30%"}} />
            <img src="/images/lines.svg" style={{position: "absolute", bottom: "200px", right: "150px"}} />
            <img src="/images/el-dark.svg" style={{position: "absolute", top: "150px",  left: "55%"}} />
            <img src="/images/el-primary.svg" style={{position: "absolute", top: "10px",  left: "40%"}} />
            <BigEl src="/images/big-el.svg" className="big-el" style={{position: "absolute", top: "-200px",  left: "30%", zIndex: "0"}} />
            <div style={{position: "absolute", bottom: "150px", left: "30%"}}>
                <Dots xCount="8" yCount="1" />
                <Dots xCount="8" yCount="1" />
                <Dots xCount="8" yCount="1" />
            </div>
            <IntroText>
                <MainText>GUI</MainText>
                <SubText>Graphical User Interface to explore the site with animations and images.</SubText>
            </IntroText>
            <StrokeText>GUI</StrokeText>
            <Proceed 
                onClick={handleClick}
            >Proceed to Gui</Proceed>
            
        </GuiContent>
    );
}

export default GuiContentComponent;
