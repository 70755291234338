import React, { useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";

const RevealContainer = styled.div`
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
`;

const Overlap = styled(motion.div)`
    background-color: #1042FF;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    transform: translateX(-100%);
`;

const Content = styled(motion.div)`
    overflow: hidden;
`;

export default (props) => {

    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    
    return (
        <RevealContainer ref={ref}>
            <Content 
                initial="hide"
                animate={controls}
                transition={{ duration: .5, delay: props.delay, ease: "easeOut" }}
                variants={{
                    visible: { transform: "translate(0px, 0%)" },
                    hide: { transform: "translate(0px, 100%)" }
                }}
            >
                {props.children}
            </Content>
            <Overlap
                initial="hide"
                animate={controls} 
                transition={{ duration: 1.5, delay: props.odelay, ease: [.52,.24,.39,.76] }} 
                variants={{
                    visible: { opacity: 1, transform: "translateX(101%)" },
                    hide : { transform: "translateX(-100%)" }
                }}
            />
        </RevealContainer>
    );
}