import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* components */
import Scroll from "../../common/ScrollIndicator";

const PanelContainer = styled.section`
    right: 0;
    position: relative;
    width: 16.666%;
    height: 100vh;
    position: fixed;
    float:left;
    padding: 30px 30px 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    a {
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
        align-self: flex-start;
        width: 100%; 
    }

    @media (max-width: 992px) {
        & {
            width: 8.333333333333333%;
            left: unset;
            right: 0; 
            display: flex;
            flex-direction: inherit;
            justify-content: center;
            align-items: center;
            padding: 0px;
        }
    }
`;

const Credit = styled.p`
    align-self: flex-end; 
    width: 100%; 
    font-size: 14px;
    font-weight: 300;

    @media (max-width: 992px) {
        & {
            display: none;
        }
    }
`;

const SwitchContainer = styled.div`
    @media (max-width: 992px) {
        & {
            display: none;
        }
    }
`;

const Switch = styled.div`
    cursor: pointer;
`;

const handleSwitch = () => {
    sessionStorage.setItem('userview', 'cli');
    window.location.href = '/';
}

const RightPanel = () => {
    return (
        <PanelContainer>
            <SwitchContainer>
                <Switch onClick={handleSwitch}>Swtich to CLI</Switch>
            </SwitchContainer>
            <Scroll />
            <Credit>&copy; {(new Date()).getFullYear()} Edrey Ching</Credit>
        </PanelContainer>
    );
}

export default RightPanel;