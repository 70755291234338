const Clear = (t) => {
    t.setState({ 
        responses: [],
        welcome: ''
    });
}

const Switch = (t, command) => {
    sessionStorage.setItem('userview', 'gui');
    var url = command.split(" ")[1] ?? '';
    window.location.href = '/' + url;

}

const NameGuide = _ => {
    var audio = document.getElementById("guide"); 
    audio.play(); 
}

const Flush = _ => {
    sessionStorage.clear();
    window.location.reload();
}

export { Clear, Switch, NameGuide, Flush }