import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import ReactHtmlParser from 'react-html-parser';

/* Components */
import { SmallHeading, BackgroundText } from "../../../common/typography/Text";

import Projects from "../../../assets/projects.json";
import Content from "../../../assets/content.json";

const ProjectContainer = styled(motion.section)`
    position: relative;
`;

const ProjectSub = styled.div`
    width: 50%;
    float: left;
    margin-top: 20px;

    p {
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SectionTitle = styled(SmallHeading)`
    margin: 0px;
`; 

const LeftText = styled.div`
    transform: rotateZ(270deg);
    left: -492px;
    position: absolute;
    top: 300px;
    width: 100%;
    display: table;
    z-index: -1;

    h2 {
        color: #305dff2b;
    }
`;

const ProjectContainerItem = styled.div`
    width: 100%;
    display: table;
    position: relative;
`;


const Item = styled(motion.div)`
    background-color: #ededed;
    width: 80%;
    float: right; 
    height: 500px;
    margin-top: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    
    @media (max-width: 1280px) {
        width: 90%;
        height: 400px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ContentItem = styled.div`
    padding: 20px;
    width: 100%;
    float: left;

    p {
        font-size: 14px;
        margin: 0px;
    }

    h2 {
        margin: 0px;
    }
`;

const Project = styled(motion.div)`
    width: 50%;
    float: left;

    @media (max-width: 768px) {
        width: 100%;
    }

    &:nth-child(odd) {
        ${Item} {
            margin-top: 150px;

            @media (max-width: 768px) {
                margin-top: unset;
            }
        }   
    }
`;

const ItemImage = styled(motion.img)`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const ContentWrapper= styled.div`
    width: 80%;
    float: right;

    @media (max-width: 1280px) {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export default () => {
    return (
            <ProjectContainer>
                <LeftText>
                    <BackgroundText text="Projects" />
                </LeftText>
                <SectionTitle>Projects for clients</SectionTitle>
                <ProjectSub>
                    {ReactHtmlParser(Content.works.client)}
                </ProjectSub>

                <ProjectContainerItem>
                    <ProjectItem 
                        image={Projects.m.rcmi.banner}
                        name={Projects.m.rcmi.name} 
                        link={Projects.m.rcmi.slug}
                        platform={Projects.m.kline.platform}
                    />
                    <ProjectItem 
                        image={Projects.m.lb.banner}
                        name={Projects.m.lb.name} 
                        link={Projects.m.lb.slug}
                        platform={Projects.m.kline.platform}
                    />
                </ProjectContainerItem>
                <div style={{position: "relative"}}>
                    <img src="/images/big-el.svg" style={{position: "absolute", right: 0, top: "-200px", zIndex: -1}} />
                </div>
                <ProjectContainerItem>
                    <ProjectItem 
                        image={Projects.m.salestracq.banner}
                        name={Projects.m.salestracq.name} 
                        link={Projects.m.salestracq.slug}
                        platform={Projects.m.kline.platform}
                    />
                    <ProjectItem 
                        image={Projects.m.kline.banner}
                        name={Projects.m.kline.name} 
                        link={Projects.m.kline.slug}
                        platform={Projects.m.kline.platform}
                    />
                </ProjectContainerItem>
            </ProjectContainer>
    );
}

const ProjectItem = ({ image, name, link, platform }) => {

    const history = useHistory();
    const controls = useAnimation();
    const [initposition, setPosition] = useState(0);

    const handleProjectClick = (e) => {
        
        let position = e.target.getBoundingClientRect();
        
        setPosition({
            height : position.height + "px", 
            width : position.width + "px",
            top : position.y + "px",
            left: position.x + "px" ,
            position: "fixed",
            margin: "0px"
        });
    }

    useEffect(() => {
        if (initposition) {

            controls.start({
                width: "calc(100vw - 16.666%)",
                left: "16.666%",
                top: "0px",
                height: "100vh",
                zIndex: "5",
            });

            setTimeout(() => {
                history.push(link);           
            }, 700);
        }
    }, [initposition]);

    return (
        <AnimatePresence exitBeforeEnter>
            <Project exit={{opacity: 0}} onClick={handleProjectClick}>
                <Item
                    onClick={handleProjectClick} 
                    data-link="asdasd" 
                    animate={controls}
                    transition={{duration: .5}}
                    initial={initposition}
                    style={initposition}
                >
                    <ItemImage   
                        initial={{scale: 1.2}}
                        whileHover={{
                            scale: 1,
                            transition: { duration: .5 },
                        }}
                        src={image}
                    />
                </Item>
                <ContentWrapper>
                    <ContentItem>
                        <h2>{name}</h2>
                        <p>{platform}</p>
                    </ContentItem>
                </ContentWrapper>
            </Project>
        </AnimatePresence>
    );
}