import React, {Component} from 'react';
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';

import SideTime from './Time';

const CommandInputed = styled.p`
    color: #fff;
    width: calc(100% - 50px);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
`;

class Default extends Component {
    render() {
        return (
            <div>
                <SideTime time={this.props.time}/>
                <CommandInputed>{this.props.command}</CommandInputed>
                <div>{ReactHtmlParser(this.props.children)}</div>
            </div>
        );
    }
}



export default Default;
