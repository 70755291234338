import React from "react";
import styled from "styled-components";

import {RoundText} from "../../../common/typography/Text";
import Skills from '../../../assets/skills';

const SectionHeader = styled.div`
    display: flex;
    align-items: center;

    ${RoundText} {
        margin-right: 20px;
    }

    p {
        margin: 0px;
        font-weight: 600;
    }
`;

const Skill = styled.div`
    width: 60px;
    height: 60px;
    background-color: #ededed;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
    transition: .2s ease-in-out;

    img {
        width: 80%;
        display: block;
        margin: auto;
    }

    &:hover {
        width: 65px;
        height: 65px;
    }
`;

const SkillContianer = styled.div`
    width: 65px;
    height: 65px;
    margin: 20px;
    float: left;
`;

const SkillWrapper = styled.div`
    margin: 50px 0px;
`;

export default () => {
    return (
        <section>
            <SectionHeader>
                <RoundText>03</RoundText>
                <p>Expertise</p>        
            </SectionHeader>
            <SkillWrapper>
            {
                    Skills.map(((skill, index) =>
                    <SkillContianer key={`${skill.name}${index}`}>
                        <Skill>
                            <img src={skill.path} title={skill.name} />
                        </Skill>
                    </SkillContianer>
                    ))
                }
            </SkillWrapper>
                
            
        </section>
    );
}