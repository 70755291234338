import React from "react";
import styled from "styled-components";

/* Components */
import { LargeHeading } from "../../../common/typography/Text";
import { SmDots } from "../../../common/Dots";
import { RevealSlide, SlideUp } from "../../../common/animation/Animation";

const MotoContainer = styled.div`
    width: 75%;
    float: left;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const AboutContainer = styled.div`
    width: 25%;
    float: left;
    margin-top: 200px;
    align-self: flex-end;

    @media (max-width: 768px) {
        margin-top: 20px;
        width: 100%;
    }
`;

const DotsBackground = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: -2;
`;

const MainText = styled(LargeHeading)`
    margin-bottom: 0px;

    @media (max-width: 1280px) {
        font-size: 65px;
    }

    @media (max-width: 768px) {
        font-size: 50px;
        line-height: 1;
    }

    @media (max-width: 576px) {
        font-size: 40px;
    }

    @media (max-width: 414px) {
        font-size: 35px;
    }
`;

const Section = styled.section`
    display: flex;
    position: relative;
`;

export default () => {
    return (
        <Section>
            <MotoContainer>
                <RevealSlide delay="1.1" odelay="0">
                    <MainText>
                        <span>Passion</span> for <span>web</span>
                    </MainText>
                </RevealSlide>
                <RevealSlide delay="1.1" odelay=".1">
                    <MainText >
                        <span>development</span>
                    </MainText>
                </RevealSlide>
                <RevealSlide delay="1.1" odelay=".2">
                    <MainText>
                        &amp; <span>technology</span>
                    </MainText>
                </RevealSlide>
            </MotoContainer>
            <DotsBackground>
                <SmDots xCount="12" yCount="10" />
            </DotsBackground>
            
            <AboutContainer>
                <SlideUp delay="1.1">
                    <p>I'm Edrey Ching, I'm inlove 
                    with technologies stuff, I 
                    do fullstack development 
                    and UI/UX.</p>
                </SlideUp>
            </AboutContainer>
            
        </Section>
    );
}