import React from "react";
import styled from "styled-components";

const Form = styled.div`
    width: 50%;
    float: left;

    @media (max-width: 768px) {
        margin-top: 50px;
        width: 100%;
    }
`;

const BackgroundImage = styled.img`
    opacity: .08;
`;

export default () => {

  return (
    <Form>
      <BackgroundImage src="/images/send.svg" />
    </Form>
  );
}