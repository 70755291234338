import React, { useLayoutEffect  } from 'react';
import { Route, useLocation, Switch } from "react-router-dom";

import Gui from "./gui/pages/Landing";
import About from "./gui/pages/About";
import Contact from "./gui/pages/Contact";
import Works from "./gui/pages/Works";
import Projects from "./gui/pages/Projects";
import Cli from "./Cli";
import Landing from "./Landing";
import NotFound from "./gui/pages/NotFound"
export default () => {
  
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
      window.scrollTo(0, 0);
  }, [location.pathname]);
  
  if (sessionStorage.getItem('userview') === 'gui') { 
    return (
      <div style={{width:"100%", display:"table"}}>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/about" component={About} />
              <Route exact path="/works" component={Works} />
              <Route exact path="/works/:type/:slug" component={Projects}/>    
              <Route exact path="/" component={Landing} />
              <Route component={NotFound} />
          </Switch>
      </div>
    );
  } else {
    return (
      <Switch location={location} key={location.pathname}>
        <Route component={Landing} />
      </Switch>
    );
  }
}