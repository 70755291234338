import React from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import ReactHtmlParser from 'react-html-parser';

import { RoundText } from "../../../common/typography/Text";
import { Dots, SmDots } from "../../../common/Dots";
import { RevealSlide, SlideUp } from "../../../common/animation/Animation";
import Content from "../../../assets/content.json";

const SectionHeader = styled.div`
    display: flex;
    align-items: center;

    ${RoundText} {
        margin-right: 20px;
    }

    p {
        margin: 0px;
        font-weight: 600;
    }
`;

const BigText = styled(motion.div)`
    font-size: 100px;
    color: #305dff2b;
    font-weight: 700;
    right: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    line-height: 0;

    @media (max-width: 1280px) {
        font-size: 80px;
    }

    @media (max-width: 768px) {
        font-size: 60px;
    }

    @media (max-width: 576px) {
        font-size: 40px;
    }
`;

const Container = styled.div`
    position: relative;
    margin: 100px 0px 0px 0px;

    p {
        width: 50%;
        margin: 10px 0px;
        font-size: 18px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
`;

const DotsContainer = styled.div`
    position: absolute;
    top: 150px;
    right: 0px;
`;

const DotsBackground = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: -2;
`;

const Section = styled.section`
    position: relative;
`;

export default () => {

    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, value => value / -20);

    return (
        <Section>
            <RevealSlide>
                <SectionHeader>
                    <RoundText>02</RoundText>
                    <p>Experiences</p>        
                </SectionHeader>
            </RevealSlide>
            <Container>
                <BigText style={{y:y1}}> { (new Date()).getFullYear() - 2016 }yrs </BigText>
                <RevealSlide>
                    {ReactHtmlParser(Content.about.exp.yrs.content)}
                </RevealSlide>
                <DotsContainer>
                    <SlideUp delay="2">
                        <Dots xCount="8" yCount="6" />
                    </SlideUp>
                </DotsContainer>
            </Container>
            <Container>
                <DotsBackground>
                    <SlideUp delay="2">
                        <SmDots xCount="12" yCount="10" />
                    </SlideUp>
                </DotsBackground>
                <BigText style={{y:y1}}>5 Industries</BigText>
                <RevealSlide>
                    {ReactHtmlParser(Content.about.exp.industries.content)}
                </RevealSlide>
            </Container>
            <Container>
                <BigText style={{y:y1}}>30+ Brands</BigText>
                <RevealSlide>
                    {ReactHtmlParser(Content.about.exp.projects.content)}
                </RevealSlide>
            </Container>
        </Section>
    );
}