import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";

/* Components */
import Grid from "../../common/Grid";
import Menu from "../../common/menu/Menu";
import { LeftPanel, RightPanel, Footer } from "../layout/Layout";
import { BackgroundText } from "../../common/typography/Text";
import Transition from "../../common/Transition";

import Intro from "./components/Intro";
import Projects from "./components/ProjectList";
import OldProjects from "./components/OldWorks";
import Fun from "./components/Fun";
import Brand from "./components/Brands";

const Container = styled.section`
    width: 66.664%;
    float:left;
    position: relative;
    left: 16.666%;

    @media (max-width: 992px) {
        & {
            left: 8.333333333333333%;
            width: 83.33333333333333%;
        }
    }

    section {
        margin-top: 130px;
        display:table;
        width: 100%;
    }
`;

const HeadingContainer = styled.div`
    position: relative;
    text-align: center;
    width: 100%;
    top: 200px;

    h2 {
        width: 100%;
    }
`;

export default () => {

    /* Handling Menu */
    const [state, setState] = useState({visible:false});

    const handleMenuToggle = () => {
        setState({ visible: !state.visible});
    }
    const menuVisibleStyle = state.visible ? {position : "relative", zIndex : "-10"} : {}; 
    
    /* Scroll Animation */
    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, value => value / 5);

    document.title = 'Edrey Ching | Works';

    return (

      <div>
          <Menu visible={state.visible} menuClicked={handleMenuToggle} />
          <LeftPanel style={menuVisibleStyle} menuClicked={handleMenuToggle} />
          <Container style={menuVisibleStyle}>
            <motion.div style={{y : y1}}>
                <div style={{position: "relative"}}>
                    <img src="/images/el-primary.svg" style={{position: "absolute", top: "100px",  right: "10%"}} />
                </div>
                <HeadingContainer>
                    <BackgroundText text="Works" />
                </HeadingContainer>
            </motion.div>
              <Intro />
                <div style={{position: "relative"}}>
                    <img src="/images/lines.svg" style={{position: "absolute", top: "-50px",  left: "10%"}} />
                </div>
              <Projects />
              <OldProjects />
                <div style={{position: "relative"}}>
                    <img src="/images/tri.svg" style={{position: "absolute", top: "-50px",  right: "20%"}} />
                    <img src="/images/el-dark.svg" style={{position: "absolute", top: "50px",  left: "0px"}} />
                </div>
              <Fun />
              <div style={{position: "relative"}}>
                    <img src="/images/lines.svg" style={{position: "absolute", top: "0px",  right: "50%"}} />
                    <img src="/images/x.svg" style={{position: "absolute", top: "50px",  left: "60%"}} />
                </div>
              <Brand />
              <Footer />
          </Container>
          <RightPanel style={menuVisibleStyle} />
          <Grid style={menuVisibleStyle} />
          <Transition menuVisible={state.visible} />
      </div>
    );
}