import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

/* Components */
import Grid from "../../common/Grid";
import Menu from "../../common/menu/Menu";
import Transition from "../../common/Transition";
import {LeftPanel, RightPanel, Footer} from "../layout/Layout";
import Hero from "./components/Hero";
import Projects from "../Works/components/ProjectList";
import Moto from "./components/Moto";
import Overview from "./components/Overview";

const Container = styled.section`
    width: 66.664%;
    float:left;
    position: relative;
    left: 16.666%;

    section {
        margin-top: 150px;
        display:table;
        width: 100%;
    }

    @media (max-width: 992px) {
        & {
            left: 8.333333333333333%;
            width: 83.33333333333333%;
        }
    }
`;

const Gui = () => {

    /* Handling Menu */
    const [state, setState] = useState({visible:false});

    const handleMenuToggle = () => {
        setState({ visible: !state.visible});
    }
    const menuVisibleStyle = state.visible ? {position : "relative", zIndex : "-10"} : {}; 
    
    return (
        <motion.div exit={{opacity : 0}}>
            <Menu visible={state.visible} menuClicked={handleMenuToggle} />
            <LeftPanel style={menuVisibleStyle} menuClicked={handleMenuToggle} />
            <Container style={menuVisibleStyle}>
                <Hero />
                <div style={{position: "relative"}}>
                    <img src="/images/el-dark.svg" style={{position: "absolute", bottom: "-50px",  left: "60%"}} />
                </div>
                <Projects />
                <div style={{position: "relative"}}>
                    <img src="/images/big-el.svg" style={{position: "absolute", top: "-327px", zIndex: -1}} />
                </div>
                <Moto />
                <div style={{position: "relative"}}>
                    <img src="/images/tri.svg" style={{position: "absolute", bottom: "-50px",  left: "60%"}} />
                    <img src="/images/el-primary.svg" style={{position: "absolute", bottom: "-150px",  left: "40%"}} />
                </div>
                <Overview />
                <Footer />
            </Container>
            <RightPanel style={menuVisibleStyle} />
            <Grid style={menuVisibleStyle} />
            <Transition menuVisible={state.visible} />
        </motion.div>
    );
}

export default Gui;