import React from "react";
import styled, {css} from "styled-components";
import { Link } from "react-router-dom";

/* Components */
import LeftMost from "./components/LeftMost";
import MenuItem from "./components/MenuItem";
import {Dots, SmDots} from "../Dots";
import Lines from "../DiagonalLines";

const animation = css`
    transform-origin: center left;
    transition: -webkit-transform .6s cubic-bezier(.4,0,0,1) .6s;
    transition: transform .6s cubic-bezier(.4,0,0,1) .6s;
    transition: transform .6s cubic-bezier(.4,0,0,1) .6s,-webkit-transform .6s cubic-bezier(.4,0,0,1) .6s;
`;

const MenuBackground = styled.div`
    width: 16.666%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 100vh;
    z-index: 5;
    background-color: #f6f6f6;
    transform: scaleX(0);
    ${animation}
    border-left: 1px solid rgba(0,0,0,.08);
    transition-delay: .8s;

    @media (max-width: 992px) {
        &:nth-child(1) {
            display: none;
        }

        &:nth-child(2), &:nth-child(5) {
            width: 8.333333333333333%;
        }

        &:nth-child(3), &:nth-child(4) {
            width: 41.66666666666667%;
        }
    }
`;

const MenuContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    left: -999999vw;
    transition: .03s;
    width: 100%;
    transition-delay: 1s;
    z-index: 5;

    &.active {
        transition-delay: 0s;
        left: 0vw;

        ${MenuBackground} {
            transform: scaleX(1);
            transition-delay: .1s;
        }
    }
`;

const MenuContentWrapper = styled.div`
    width: 66.664%;
    float: left;
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;

    @media (max-width: 992px) {
        & {
            width: 83.33333333333333%;
            display: flex;
            align-items: center;
        }
    }
`;

const MenuContent = styled.div`
    position: absolute;
    top: 0px;
    z-index: 5;
    width: 100%;
`;

const DotsContainer = styled.div`
    position: absolute;
    top: 35px;
    left: 50%;
`;

const Credits = styled.a`
    position: absolute;
    bottom: 35px;
    left: 75%;
    font-size: 12px;
    font-weight: 300;

    @media (max-width: 992px) {
        & {
            left: 0;
        }
    }    
`;
const DiagonalLines = styled.div`
    position: absolute;
    bottom: 50px;
    left: 0;

    @media (max-width: 992px) {
        & {
            display: none;
        }
    }   
`;

const SmdotsContainer = styled.div`
    position: absolute;
    z-index: -1;
    right: 15px;
    top: 20px;
`;

const Flush = styled.div`
    cursor: pointer;
    position: absolute;
    bottom: 35px;
    left: 50%;

    @media (max-width: 992px) {
        & {
            left: 0;
        }
    }     
`;

const flush = _ => {
    sessionStorage.clear();
    window.location.href = '/';
}

export default ({visible, menuClicked}) => {

    return (
        <MenuContainer className={visible ? 'active' : ''}>
            <MenuBackground />
            <MenuBackground />
            <MenuBackground />
            <MenuBackground />
            <MenuBackground />
            <MenuBackground />
            <MenuContent className={visible ? 'active' : ''}>
                <LeftMost menuClicked={menuClicked} />
                <MenuContentWrapper>
                    <div style={{display: "table", width: "100%"}}>
                        <DotsContainer>
                            <Dots xCount="8" yCount="3" />
                        </DotsContainer>
                        <Link to="/cli">
                            <Credits>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></Credits>
                        </Link>
                        <Flush onClick={flush}>Flush Sesession</Flush>
                        <DiagonalLines>
                            <Lines count="12" />
                        </DiagonalLines>
                        <MenuItem />
                    </div>
                </MenuContentWrapper>
                
                <SmdotsContainer>
                    <SmDots xCount="12" yCount="10"/>
                </SmdotsContainer>
            </MenuContent>
        </MenuContainer>            
    );
}