import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';

const Container = styled.div`
    padding: 5px 20px 5px 0px;
`;

export default ({content}) => {
    return (
        <Container>
            {ReactHtmlParser(content)}
        </Container>
    );
}