import { Help, About, Works, Contact, TF } from "./Contents";

const handleResponses = (command) => {
    const trimedCommand = command.split(" ")[0]; // this check only the first command and disregard all the options on the command
    
    switch (trimedCommand) {
        case "--h":
            return Help();
        case "about":
            return About(command);

        case "works":
            return Works(command);

        case "contact":
            return Contact();

        case "tf":
            return TF();
                
        default:
            break;
    }

}

export default handleResponses;