import React, { useState } from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";

/* Components */
import Grid from "../../common/Grid";
import Menu from "../../common/menu/Menu";
import {LeftPanel, RightPanel, Footer} from "../layout/Layout";
import { BackgroundText } from "../../common/typography/Text";
import Transition from "../../common/Transition";

import Intro from "./components/Intro";
import About from "./components/AboutIntro";
import Exp from "./components/Exp";
import Skills from "./components/Skills";
import Awards from "./components/Awards";

const Container = styled.section`
    width: 66.664%;
    float:left;
    position: relative;
    left: 16.666%;

    section {
        margin-top: 130px;
        display:table;
        width: 100%;
    }

    @media (max-width: 992px) {
        & {
            left: 8.333333333333333%;
            width: 83.33333333333333%;
        }
    }
`;

const HeadingContainer = styled.div`
    position: relative;
    text-align: center;
    width: 100%;
    top: 200px;

    h2 {
        width: 100%;
    }
`;

export default () => {

    /* Handling Menu */
    const [state, setState] = useState({visible:false});

    const handleMenuToggle = () => {
        setState({ visible: !state.visible});
    }
    const menuVisibleStyle = state.visible ? {position : "relative", zIndex : "-10"} : {}; 

    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, value => value / 5);

    document.title = 'Edrey Ching | About';

    return (
        <motion.div exit={{opacity : 0}}>
            <Menu visible={state.visible} menuClicked={handleMenuToggle} />
            <LeftPanel style={menuVisibleStyle} menuClicked={handleMenuToggle} />
            <Container style={menuVisibleStyle}>
                <motion.div style={{y : y1}}>
                    <HeadingContainer >
                        <BackgroundText text="About" />
                    </HeadingContainer>
                </motion.div>
                <Intro />
                <About />
                <div style={{position: "relative"}}>
                    <img src="/images/lines.svg" style={{position: "absolute", bottom: "-50px",  right: "10%"}} />
                    <img src="/images/el-dark.svg" style={{position: "absolute", bottom: "-250px",  left: "10%"}} />
                </div>
                <Exp />
                <div style={{position: "relative"}}>
                    <img src="/images/tri.svg" style={{position: "absolute", bottom: "-100px",  right: "10%"}} />
                    <img src="/images/lines.svg" style={{position: "absolute", bottom: "0px",  right: "20%"}} />
                </div>
                <Skills />
                {/* <Awards /> */}
                <Footer />
            </Container>
            <RightPanel style={menuVisibleStyle} />
            <Grid style={menuVisibleStyle} />
            <Transition menuVisible={state.visible} />
        </motion.div>
    );
}