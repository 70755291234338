import React from "react";
import styled from "styled-components";

import { RoundText } from "../../../common/typography/Text";

const SectionHeader = styled.div`
    display: flex;
    align-items: center;

    ${RoundText} {
        margin-right: 20px;
    }

    p {
        margin: 0px;
        font-weight: 600;
    }
`;
export default () => {
    return (
        <section>
            <SectionHeader>
                <RoundText>04</RoundText>
                <p>Awards and Recognitions</p>        
            </SectionHeader>
            
        </section>
    );
}