import React, {Component} from 'react';
import styled from "styled-components";
import SideTime from './Time';
import ReactHtmlParser from 'react-html-parser';


const CliResponse = styled.div`
    background-color: #363636;
    padding: 20px;
    border-radius: 7px;
    margin: 10px auto 10px 65px;

    h1,h2,h3,h4,h5,h6,p,a {
        color: #fff;
        text-decoration: none;
        margin:10px 0px;
      }
`;

const CommandInputed = styled.p`
    color: #fff;
    width: calc(100% - 50px);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
`;

class Response extends Component {

    render() {
        return (
            <>
                <SideTime time={this.props.time}/>
                <CommandInputed>{this.props.command}</CommandInputed>
                <CliResponse>
                    {ReactHtmlParser(this.props.response)}
                </CliResponse>
            </>
        );
    }
}



export default Response;
