import React from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";

import { Dots } from "../../../common/Dots";
import DLines from "../../../common/DiagonalLines";
import { SlideUp, RevealSlide } from "../../../common/animation/Animation";

const DotsContainer = styled(motion.div)`
    width: 50%;
    align-items: flex-start;
    float: right;
    padding: 30px;
    
`;

const LinesContainer = styled(motion.div)`
    width: 50%;
    float: left;
    position: absolute;
    top: 100px;
    left: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const HeroContainer = styled.div`
    margin-bottom: 200px;
    display: table;
    width: 100%;
    position: relative;
`;

const Content = styled.div`
    width: 100%;
    display: table;
    margin-top: 200px;
`;

const LogoContainer = styled.div`
    width: 35%;
    float: left;

    img {
        width: 80%;
        max-width: 280px;
        
        @media (max-width: 768px) {
            width: 40%;
            max-width: 40%;
            margin: auto;
            display: block;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Text = styled.div`
    width: 65%;
    float: left;
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
    }
`;

const SubText = styled.h2`
    font-weight: 700;
    margin: 0px;
    font-size: 25px;
    padding: 0px 5px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const MainText = styled.h1`
    font-size: 100px;
    font-weight: 700;
    color: #1042FF;
    margin: 0px;
    line-height: 1;
    position: relative;

    @media (max-width: 1280px) {
        font-size: 80px;
    }

    @media (max-width: 768px) {
        font-size: 60px;
    }

    @media (max-width: 576px) {
        font-size: 40px;
    }
`;

const IntroText = styled.div`
    width: 60%;
    font-size: 18px;
    margin-top: 50px;

    @media (max-width: 768px) {
        width: 100%;
        font-size: 16px;
    }
`;
const Hero = () => {

    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, value => value / 3);
    const y3 = useTransform(scrollY, value => value / 5);
    const y2 = useTransform(scrollY, value => value / -15);
    
    return (
        <HeroContainer>
            <motion.img src="/images/big-el.svg" style={{position: "absolute", top: "-327px"}} />
            <LinesContainer style={{y : y2}}>
                <DLines count="12"/>
                <motion.img src="/images/x.svg" style={{y: y1, position: "absolute", right: "0px"}} />
            </LinesContainer>
            <DotsContainer style={{y : y2}}>
                <Dots xCount="8" yCount="1" />
                <Dots xCount="8" yCount="1" />
                <Dots xCount="8" yCount="1" />
            </DotsContainer>
            <Content>
                <LogoContainer>
                    <motion.img src="/images/logo.png" />
                </LogoContainer>
                <Text>
                    <SlideUp delay="3.8">
                        <SubText>Frontend &amp; Backend</SubText>
                    </SlideUp>
                    <RevealSlide delay="4.3" odelay="3.5">
                        <MainText>Developer <motion.img src="/images/tri.svg" style={{position: "absolute", top: "0px"}} /></MainText>
                    </RevealSlide>
                    <IntroText>Hi I'm Edrey Ching, a fullstack developer &amp; designer from Philippines</IntroText>
                    <motion.img src="/images/lines.svg" style={{y: y3, position: "absolute", bottom: "-50px"}} />
                    <motion.img src="/images/el-dark.svg" style={{y: y1, position: "absolute", bottom: "-50px",  right: "50px"}} />
                </Text>
            </Content>
            <motion.img src="/images/el-primary.svg" style={{y: y2, position: "relative", bottom: "-50px",  left: "20px"}} />
            <motion.img src="/images/x.svg" style={{y: y3, position: "absolute", bottom: "-50px",  right: "50px"}} />
        </HeroContainer>
    );
}

export default Hero;