import react from 'react';
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
`;

const Lines = styled.span`
    width: 5px;
    height: 35px;
    background-color: #1042FF;
    float: left;
    margin: 0px 7px;
    transform: rotateZ(45deg);
`;

export default ({count}) => {
    const line = [];
    for (let i = 0; i < count; i++) {
        line.push(<Lines key={"dl" + i} />);
    }

    return (
        <Container>
            {line}
        </Container>
    );
}