import React from "react";
import styled from "styled-components";
import { LargeHeading } from "../../common/typography/Text";
import { Link } from "react-router-dom";

const Code = styled(LargeHeading)`
    margin-top: 100px;
    margin: 0px;
    font-size: 150px;

    strong {
        color : #1042FF;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    h2 {
        text-align: center;
        margin: 20px;
    }

    p {
        text-align: center;
        text-decoration: underline;
    }
`;

export default () => {
    return (
        <Wrapper>
            <div>
                <Code><strong>404</strong></Code>
                <h2>The page you were looking for does not exist</h2>
                <p><Link to="/">Go back Home</Link></p>
            </div>
        </Wrapper>
    );
}