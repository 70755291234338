import React, { useEffect } from "react";
import styled from "styled-components";
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";

const RevealContainer = styled.div`
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
`;
const Content = styled(motion.div)`
    overflow: hidden;
`;

export default (props) => {

    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <RevealContainer ref={ref}>
            <Content 
                initial="hide"
                animate={controls}
                transition={{ duration: .5, delay: props.delay, ease: "easeOut" }}
                variants={{
                    visible: { transform: "translate(0px, 0%)" },
                    hide: { transform: "translate(0px, 100%)" }
                }}
            >
                {props.children}
            </Content>
        </RevealContainer>
    );
}