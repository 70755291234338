import React from "react";
import styled from "styled-components";

/* Components */
import Roles from "./Roles";
import Introduction from "./Introduction";
import Section from "./Section";
import Stats from "./Stats";
import Features from "./Features";
import WhatsNext from "./WhatsNext";
import List from "./List";
import { BigImage } from "./Images";

import Projects from "../../../assets/projects.json";

const Clear = styled.div`
    clear: both;
    margin: 100px 0px;
`;

const Container = styled.div``;

const Components = {
    Roles: Roles,
    Introduction: Introduction,
    Section: Section,
    BigImage: BigImage,
    Stats: Stats,
    Features: Features,
    WhatsNext: WhatsNext
}

export default ({slug, type}) => {
    const child = [];    
    
    function children (item) {
        item.child.map((children, index) => {
            child.push(React.createElement(Components[children.name], children.props))
        })
        return child;
    }

    return (
        <Container>
            {
                Projects[type][slug].components.map((item, key) => {
                    return (
                        <>
                            { item.parent ? React.createElement(item.name, item.props, children(item))  : React.createElement(Components[item.name], item.props) }
                            {item.clear ? <Clear /> : ''}
                        </>
                )})
            }
            
            {/* <Roles roles={['Planning', 'UI/UX', 'Frontend', 'Backend']} />
            <Introduction content="Cras sagittis ipsum nec sagittis scelerisque. Duis eget purus in ligula cursus ornare eu eu ex. Sed sit amet purus sem. Vivamus bibendum, libero in malesuada ultrices, sem nibh tempor ipsum, sit amet lacinia libero lorem ac turpis. Morbi at orci ipsum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed commodo, nisl id venenatis molestie, arcu augue ultricies urna, sit amet tempus libero erat eu ligula." />
            <Clear />
            <Section widthSize="50%" title="The Challenge" content="Sed eget augue tincidunt quam fermentum mattis. Integer lobortis diam nulla, eget fermentum sapien semper non. Proin aliquet nunc euismod eros finibus imperdiet. Etiam facilisis felis eu dictum blandit. " />
            <Section widthSize="50%" title="The Solution" content="Phasellus condimentum, purus vitae vulputate aliquet, odio urna dapibus augue, vel aliquet odio nisi vel elit. Nulla ac risus in lorem consectetur mollis." />
            <Clear /> */}
            {/* <BigImage />
            <Clear /> */}
            {/* <Section titleAlign="center" widthSize="95%" title="The Outcome" content="Sed eget augue tincidunt quam fermentum mattis. Integer lobortis diam nulla, eget fermentum sapien semper non. Proin aliquet nunc euismod eros finibus imperdiet. Etiam facilisis felis eu dictum blandit. " />
            <Clear /> */}
            {/* <Stats numberColor="green" stats={[{stats: 5, name: "hey"}, {stats: 5, name: "hey"}, {stats: 5, name: "hey"}]} />
            <Clear /> */}
            {/* <Features titleAlign="center" title="Highlights of the Projects" items={[
                [
                    {name: "Testing One", details: "lorem ipsum dolor asmet"},
                    {name: "Testing Two", details: "lorem ipsum dolor asmet"},
                    {name: "Testing Three", details: "lorem ipsum dolor asmet"}
                ],
                [
                    {name: "Testing One", details: "lorem ipsum dolor asmet"},
                    {name: "Testing Two", details: "lorem ipsum dolor asmet"},
                    {name: "Testing Three", details: "lorem ipsum dolor asmet"}
                ]
            ]} />
            <Clear /> */}
            {/* <WhatsNext 
                background="#0a1225"
                color="#fff"
                bg="https://radianceofchrist.org/wp-content/uploads/2020/07/logo.png"
                items={[
                        {name: "Testing One", details: "lorem ipsum dolor asmet"},
                        {name: "Testing Two", details: "lorem ipsum dolor asmet"},
                        {name: "Testing Three", details: "lorem ipsum dolor asmet"}
                    ]}
                />
            <Clear /> */}
        </Container>
    );
}