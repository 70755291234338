import React, {useEffect, useState} from "react";
import styled from "styled-components";

const ProgressContainer = styled.div`
    background: #CACACA;
    height: 150px;
    width: 7px;
    z-index: 99;
    margin-left: 15px;

    @media (max-width: 992px) {
        & {
            margin-left: 0px;
        }
    }
`;

const PorgressBar = styled.div`
    width: 7px;
    background: #1042FF;
`;

export default () => {
    const [state, setState] = useState({scroll:0});

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    });

    const scrollHandler = () => {
        const scrollPx = document.documentElement.scrollTop;
        const winHeightPx =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolled = `${scrollPx / winHeightPx * 100}%`;
        setState({ scroll: scrolled});
    }

    const progressBarWidth = {
        height: state.scroll
      };

    return (
        <ProgressContainer>
            <PorgressBar style={progressBarWidth} />
        </ProgressContainer>
    );

}