import React from "react";
import styled from "styled-components";

const LargeHeading = styled.h2`
    font-size: 80px;
    font-weight: 700;
    line-height: 94px;

    span{
        position: relative;
        
        &:before {
            height: 35%;
            width: 100%;
            background-color: #1042FF;
            content: '';
            position: absolute;
            display: block;
            z-index: -1;
            bottom: 12px;
            padding: 0px 7px;
            left: 0px;
        }
    }

    @media (max-width: 1280px) {
        font-size: 80px;
        line-height: 1.5;
    }

    @media (max-width: 768px) {
        font-size: 60px;
        line-height: 1.5;
    }

    @media (max-width: 576px) {
        font-size: 40px;
        line-height: 1.5;
    }
`;

const MediumHeading = styled.h3`
    font-size: 60px;
    font-weight: 700;
    line-height: 84px;

    span{
        position: relative;
        
        &:before {
            height: 35%;
            width: 100%;
            background-color: #1042FF;
            content: '';
            position: absolute;
            display: block;
            z-index: -1;
            bottom: 12px;
            padding: 0px 7px;
            left: 0px;
        }
    }
`;

const SmallHeading = styled.h4`
    font-size: 35px;
    font-weight: 600;
    line-height: 47px;
`;

const BgText = styled.h2`
    position: absolute;
    z-index: -2;
    margin: 0px;
    font-size: 150px;
    line-height: 1;
    color: #ededed;
    height: 150px;
    overflow: hidden;
    line-break: anywhere;
    top: -20px;


    @media (max-width: 768px) {
        font-size: 100px;
    }

    @media (max-width: 576px) {
        font-size: 80px;
    }
`;

const BackgroundText = ({text}) => {
    return <BgText>{text}</BgText>
}

const RoundText = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #ededed;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    float: left;
`;



export {LargeHeading, MediumHeading, SmallHeading, BackgroundText, RoundText};