import React, {Component} from 'react';
import styled from "styled-components";

const SideInput = styled.div`
    color: #767676;
    font-size: 12px;
    width: 50px;
    float: left;
`;

const Input = styled.div`
    width: 100%;
`;

const CommandInput = styled.input`
    background-color: transparent;
    border: none;
    color: #fff;
    width: calc(100% - 50px);
    font-size: 14px;
    font-family: 'Roboto Mono', monospace;

    &:focus {
        outline: none;
    }
`;

class Command extends Component {

    componentDidMount() {
        this.props.handleClick();    
    }

    render() {

        return (
            <Input>
                <SideInput>Guest</SideInput>
                <CommandInput id="cmd" type="text" autoComplete="off" ref={this.props.cmdRef} onKeyDown={this.props.onKeyUp} />
            </Input>
        );
    }
}

export default Command;
