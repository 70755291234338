import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const BottomTop = keyframes`
  0% {
    bottom: -100px;
  }

  50% {
      bottom: 250px;
  }

  100% {
    bottom: -100px;
  }
`;

const TiltToRight = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-45deg);
    }
    100% {
        transform: rotate(-45deg);
    }
`;

const TiltToLeft = keyframes`
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(45deg);
    }
`;

const MenuItem = styled.div`
    width: 25%;
    float: left; 
    overflow: hidden;

    @media (max-width: 992px) {
        & {
            width: 100%;
            padding: 15px 0px;
            height: 80px;
        }
    }
`;

const FloatingBackground = styled.div`
    height: 300px;
    position: absolute;
    background: transparent;
    width: 100%;
    z-index: -1;
    top: 0px;
    overflow: hidden;
`;

const LinkContainer = styled.div`
    color: #1042FF;
    font-weight: 700;
    font-size: 45px; 
    text-align: center;
    transition: 1s;
    cursor: pointer;
    transform-origin: center left;
    transform: translate(-100%);
    transform-origin: center left;
    transition: -webkit-transform .6s cubic-bezier(.4,0,0,1) .6s;
    transition: transform .6s cubic-bezier(.4,0,0,1) .6s;
    transition: transform .6s cubic-bezier(.4,0,0,1) .6s,-webkit-transform .6s cubic-bezier(.4,0,0,1) .6s;
    animation-delay: 10s;
    position: relative;
    height: 300px;

    @media (max-width: 992px) {
        & {
            height: auto;
        }
    }

    a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        a {  
            color: blue;
        }
    }

    .active & {
        transform: translate(0%);
        transition-delay: .7s;
    }
`;

const FloatingItems = styled.span`
    width: 20px;
    height: 20px;
    display: block;
    transition: .5s;
    position: absolute;
    bottom: -100%;
    left: 50%;

    ${LinkContainer}.hvr ${FloatingBackground} &.dly10{
        animation: ${BottomTop} 2s ease-in-out, ${TiltToRight} 3s ease-in-out;
    }

    ${LinkContainer}.hvr ${FloatingBackground} &.dly20{
        animation: ${BottomTop} 2s ease-in-out, ${TiltToRight} 3s ease-in-out;
        animation-delay: 0.1s;
    }

    ${LinkContainer}.hvr ${FloatingBackground} &.dly10.toleft{
        animation: ${BottomTop} 2s ease-in-out, ${TiltToLeft} 3s ease-in-out;
    }

    ${LinkContainer}.hvr ${FloatingBackground} &.dly20.toleft{
        animation: ${BottomTop} 2s ease-in-out, ${TiltToLeft} 3s ease-in-out;
        animation-delay: 0.1s;
    }
`;

export default () => {
    const [state, setState] = useState([]);

    const animateIn = (e) => {
        let type = e.target.getAttribute('data-menu');
        setState([...state, type]);
    }

    const animateOut = (e) => {
        let type = e.target.getAttribute('data-menu');
        setState(state.filter((e) => (e !== type)));
    }

    return (
       <>
        <MenuItem>
            <LinkContainer data-menu="home" className={state.includes('home') ? 'hvr' : ''} onMouseEnter={animateIn}>
                <Link to="/" data-menu="home">Home</Link>
                <FloatingBackground data-menu="home">
                    <FloatingItems data-menu="home" className="dly10" onAnimationEnd={animateOut} style={{left:"30px"}}>
                        <img src="/images/x.svg" />
                    </FloatingItems>
                    <FloatingItems data-menu="home" className="dly20" onAnimationEnd={animateOut} style={{left:"50px"}}>
                        <img src="/images/lines.svg" />
                    </FloatingItems>
                    <FloatingItems data-menu="home" className="dly20 toleft" onAnimationEnd={animateOut} style={{left:"90px"}}>
                        <img src="/images/logo.png" style={{width: "80px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="home" className="dly20" onAnimationEnd={animateOut} style={{left:"0px"}}>
                        <img src="/images/el-dark.svg" />
                    </FloatingItems>
                </FloatingBackground>
            </LinkContainer>
        </MenuItem>
        <MenuItem>
            <LinkContainer data-menu="about" className={state.includes('about') ? 'hvr' : ''} onMouseEnter={animateIn}>
                <Link to="/about" data-menu="about">About</Link>
                <FloatingBackground data-menu="about">
                    <FloatingItems data-menu="about" className="dly10" onAnimationEnd={animateOut} style={{left:"30px"}}>
                        <img src="/images/volume.svg" />
                    </FloatingItems>
                    <FloatingItems data-menu="about" className="dly20 toleft" onAnimationEnd={animateOut} style={{left:"50px"}}>
                        &#x1f44b;
                    </FloatingItems>
                    <FloatingItems data-menu="about" className="dly10" onAnimationEnd={animateOut} style={{left:"80px"}}>
                        <img src="/images/logos/ubuntu.png" style={{width: "50px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="about" className="dly10 toleft" onAnimationEnd={animateOut} style={{right:"0px"}}>
                        <img src="/images/logos/laravel.png" style={{width: "50px"}} />
                    </FloatingItems>
                </FloatingBackground>
            </LinkContainer>
        </MenuItem>
        <MenuItem>
            <LinkContainer data-menu="work" className={state.includes('work') ? 'hvr' : ''} onMouseEnter={animateIn}>
                <Link to="/works" data-menu="work">Works</Link>
                <FloatingBackground data-menu="work">
                    <FloatingItems data-menu="work" className="dly10 toleft" onAnimationEnd={animateOut} style={{right:"0px"}}>
                        <img src="/images/projects/smb.jpg" style={{width: "70px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="work" className="dly20" onAnimationEnd={animateOut} style={{left:"0px"}}>
                        <img src="/images/projects/lab1.png" style={{width: "70px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="work" className="dly10 toleft" onAnimationEnd={animateOut} style={{left:"30px"}}>
                        <img src="/images/projects/fsm.jpg" style={{width: "70px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="work" className="dly20" onAnimationEnd={animateOut} style={{left:"80px"}}>
                        <img src="/images/projects/faculty.png" style={{width: "70px"}} />
                    </FloatingItems>
                </FloatingBackground>
            </LinkContainer>
        </MenuItem>
        <MenuItem>
            <LinkContainer data-menu="contact" className={state.includes('contact') ? 'hvr' : ''} onMouseEnter={animateIn}>
                <Link to="/contact" data-menu="contact">Contact</Link>
                <FloatingBackground data-menu="contact">
                    <FloatingItems data-menu="contact" className="dly20" onAnimationEnd={animateOut} style={{right:"0px"}}>
                        <img src="/images/send.svg" style={{width: "40px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="contact" className="dly10 toleft" onAnimationEnd={animateOut} style={{left:"30px"}}>
                        <img src="/images/viber.svg" style={{width: "40px"}} />
                    </FloatingItems>
                    <FloatingItems data-menu="contact" className="dly20" onAnimationEnd={animateOut} style={{left:"80px"}}>
                        <img src="/images/whatsapp.svg" style={{width: "40px"}} />
                    </FloatingItems>
                </FloatingBackground>
            </LinkContainer>
        </MenuItem>
       </>
    );
}