import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';


const Feature = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

const Number = styled.div`
        background-color: ${props => props.color ?? "transparent"};
        margin-right: 20px;
        width: 70px;
        height: 70px;
        border-radius: ${props => props.rounded ? "50%" : 'none'};
        font-size: 35px;
        justify-content: center;
        font-weight: 700;
        float: left;
        align-items: center;
        display: flex;
`;

const Title = styled.h3`
    margin: 0px;
    color: inherit;

`;

const Details = styled.div``;

const Description = styled.div`
    display: block;
    color: inherit;
`;

export default ({item, count, rounded = true, color}) => {
    return (
        <>
            <Feature key={item.title+count}>
                <Number color={color} rounded={rounded}>{count}</Number>
                <Details>
                    <Title>{item.name}</Title>
                    <Description>{item.details ? ReactHtmlParser(item.details) : ''}</Description>
                </Details>
            </Feature>
        </>
    );
}