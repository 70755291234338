import React, { Component } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

import CliContent from "./components/CliContent";
import GuiContent from "./components/GuiContent";

import CliView from "../Cli";
import GuiView from "../gui/pages/Landing";

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
`;

const CliContainer = styled(motion.div)`
    width: 50%;
    background-color: #0c0c0c;
    height: 100%;
    position: absolute;
    float: left;
    max-width: 75%;
    min-width: 25%;
    overflow: hidden;
    z-index: 3;

    @media (max-width: 768px) {
        width: 100% !important;
        max-width: 100%;
        height: 50vh;
        position: relative;
    }
`;

const GuiContainer = styled.div`
    width: 100%;
    min-width: 25%;
    background-color: #ededed;
    height: 100%;
    float: right;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        height: 50vh;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
        width: 40%;
    }
`;

const ImageContainerCli = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100vw;

    img {
        width: 40%;
    }
`;

class Gui extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cliWidth: {width : "50%"}
          };
    }

    mouseMove = (e) => {
        let width = (e.clientX / document.documentElement.clientWidth) * 100;
        
        if (width > 50) {
            width = 50 - (width - 50);
        } else {
            width = 50 + (50 - width);
        }

        this.setState({ 
            cliWidth: {width : width + '%'},
          });
    }

    handleGuiClick = _ => {
        sessionStorage.setItem('userview', 'gui');
        this.forceUpdate();
    }

    handleCliClick = _ => {
        sessionStorage.setItem('userview', 'cli');
        this.forceUpdate();
    }

    render() {

        if (sessionStorage.getItem('userview') === 'cli') {
            return <CliView />
        }

        if (sessionStorage.getItem('userview') === 'gui') {
            return <GuiView />
        }

        return (
            <Wrapper onMouseMove={this.mouseMove}>
                <CliContainer initial={{width: "50%"}} animate={this.state.cliWidth} transition={{ duration: 0.3, ease: "easeOut" }}>
                    <CliContent handleClick={this.handleCliClick} />
                    <ImageContainerCli>
                        <img src="/images/CLI mockup.png" />
                    </ImageContainerCli>
                </CliContainer>
                <GuiContainer>
                    <GuiContent handleClick={this.handleGuiClick} />
                    <ImageContainer>
                        <img src="/images/GUI mockup.png" />
                    </ImageContainer>
                </GuiContainer>
            </Wrapper>
        );
    }
}

export default Gui;