import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { animate } from "framer-motion";
/* Components */
import { SmallHeading } from "../../../common/typography/Text";
import { SlideUp, RevealSlide } from "../../../common/animation/Animation";


const Container = styled.div`
    width: 100%;
    background-color: #1042FF;
    color: #fff;
    margin-bottom: 50px;
    display: table;
`;

const Item = styled.div`
    width: 25%;
    float: left;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    display: block;
    margin: auto;
    padding: 60px 0px;

    @media (max-width: 768px) {
        width: 50%;
    }

    @media (max-width: 576px) {
        width: 100%;
    }

`;

const ItemContainer = styled.div`
    p {
        color: #fff;
        margin: 0px;
        font-size: 14px;
    }
`;

const Number = styled(SmallHeading)`
    && {
        margin: 0px;
        color: #fff;
    }
`;

const SectionTitle = styled(SmallHeading)`
    && {
        font-weight: 700;
    }
`;

function Counter({ from, to }) {
    const nodeRef = useRef();
  
    useEffect(() => {
      const node = nodeRef.current;
  
      const controls = animate(from, to, {
        duration: 1,
        onUpdate(value) {
          node.textContent = value.toFixed(2);
        }
      });
  
      return () => controls.stop();
    }, [from, to]);
  
    return <p ref={nodeRef} />;
  }

export default () => {
    
    return (
        <section>
            <SlideUp delay=".5">
                <SectionTitle>Overview of what I've done</SectionTitle>
            </SlideUp>
            <RevealSlide delay="1.1" odelay="0">
                <Container>
                    <Item>
                        <ItemContainer>
                            <Number>{ (new Date()).getFullYear() - 2016 }</Number>
                            <p>Years of Experience</p>
                        </ItemContainer>
                    </Item>
                    <Item>
                        <ItemContainer>
                            <Number><small>Over</small> 50</Number>
                            <p>Project Finished</p>
                        </ItemContainer>
                    </Item>
                    <Item>
                        <ItemContainer>
                            <Number>5</Number>
                            <p>Different Industries</p>
                        </ItemContainer>
                    </Item>
                    <Item>
                        <ItemContainer>
                            <Number>20+</Number>
                            <p>Brands I've worked with</p>
                        </ItemContainer>
                    </Item>
                </Container>
            </RevealSlide>
            
        </section>
    );
}