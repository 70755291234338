import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';

/* Components */
import { SmallHeading } from "../../../common/typography/Text";

const Container = styled.div`
    width: ${props => props.size};
    float: left;
    padding: 5px 20px 5px 0px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Title = styled(SmallHeading)`
    margin: 0px;
    text-align: ${props => props.titleAlign};
`;

const Content = styled.div`
    margin-top: 20px;
    line-height: 28px;
`;

export default ({title, content, widthSize, titleAlign}) => {
    return (
        <Container size={widthSize}>
            <Title titleAlign={titleAlign}>{title}</Title>
            <Content>
                {ReactHtmlParser(content)}
            </Content>
        </Container>
    );
}