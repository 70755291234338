import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const PanelConatiner = styled.section`
    width: 16.666%;
    height: 100vh;
    position: fixed;
    float:left;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 992px) {
        & {
            width: 8.333333333333333%;
            padding: 8px;
        }
    }
`;

const LogoAnchor = styled.a`
    @media (max-width: 992px) {
        & {
            display: none;
        }
    }
`;

const Logo = styled.img`
    width: 60px;
    height: auto;
    align-self: flex-start;    

    @media (max-width: 992px) {
        & {
            display: none;
        }
    }
`;

const Menu = styled.div`
    align-self: center;
    width: 100%;
    cursor: pointer;

    @media (max-width: 992px) {        
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    }

    a {
        display: flex;
        align-items: center;

        @media (max-width: 992px) {
            & {
                height: 30px;
                margin: 0px;
             
                span {
                    display: none;
                }
            }
        }
    }
`;

const MenuIcon = styled.div`
    width: 3px;
    height: 30px;
    background-color: #191919;
    float: left;
    margin-right: 5px;
`;

const Icon = styled(motion.img)`
    width: 25px;
    cursor: pointer;
`;

const IconContainer = styled.div`
    a {
        width:100%;
        display: block;
        margin-bottom: 20px;
    }

    @media (max-width: 992px) {
        & {
            display: none;
        }
    }
`;

const SocialMedia = styled.nav`
    width: 100%;

    a {
        width:auto;
        display: unset;
        padding: 0px 10px;
    }
`;

const SmIcons = styled(motion.div)`
    position: absolute;
    bottom: 0px;
    left: 50px;
    width: 0%;
    overflow: hidden;
`; 

const LeftPanel = ({menuClicked}) => {
    
    const [share, setShare] = useState(0);

    const toggleShare = () => {
        setShare(!share);
    }


    return (
        <PanelConatiner>
            <LogoAnchor href="/">
                <Logo src="/images/logo.png" />
            </LogoAnchor>
            <Menu> 
                <a onClick={menuClicked}>
                    <MenuIcon />    
                    <MenuIcon />    
                    <MenuIcon />
                    <span>Menu</span>    
                </a> 
            </Menu>
            <IconContainer>
                <a href="https://www.linkedin.com/in/edreyching" target="_blank"><Icon whileHover={{scale: 1.1}} src="/images/linkedin.svg" /></a>
                <span style={{position: "relative", display: "block"}}><Icon whileHover={{scale: 1.1}} src="/images/share.svg" onClick={toggleShare} />
                    <SmIcons initial={{width: "0%"}} animate={{width: share ? "100%" : "0%"}}>
                        <SocialMedia>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fedreyching.dev%2F" target="_blank"><Icon whileHover={{scale: 1.1}} src="/images/facebook.svg" /></a>
                        </SocialMedia>
                    </SmIcons>
                </span>
            </IconContainer>
        </PanelConatiner>
    );
}

export default LeftPanel;