import React from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";

/* Components */
import { Dots } from "../../../common/Dots";
import { LargeHeading } from "../../../common/typography/Text";
import Lines from "../../../common/DiagonalLines";
import { RevealSlide, SlideUp } from "../../../common/animation/Animation";

const DotsContainer = styled(motion.div)`
    position: absolute;
    left: 50%;
    top: 30px;
    width: 100%;
`;

const GreetingsWrapper = styled.div`
    height: 260px;
    display: flex;
    align-items: center;
    position: relative;

    ${LargeHeading} {
        margin-bottom: 10px;
    }

    p {
        margin: 0px;
        font-size: 18px;
    }
`;

const HDotsContainer = styled(motion.div)`
    position: absolute;
    right: 0px;
`;

const DiagonalLines = styled(motion.div)`
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;

    
    @media (max-width: 980px) {
        display: none;
    }
`;

const Message = styled(LargeHeading)`
    margin-top: 100px;

    strong {
        color : #1042FF;
    }
`;

const IntroMessage = styled.div`
    font-size: 18px;
    font-weight: 600;

    span{
        position: relative;
        
        &:before {
            height: 15px;
            width: 90%;
            background-color: #1042FF;
            content: '';
            position: absolute;
            display: block;
            z-index: -1;
            bottom: -2px;
            padding: 0px 7px;
            left: 0px;
        }
    }
`;

const IntroWrapper = styled(motion.div)`
    position: relative;
    margin: 150px 0px;
`;

const Wave = styled(motion.strong)`
    transform-origin: 70% 70%;
    display: inline-block;
`;

const Pronounciation = styled.div`
    font-size: 25px;
    float: right;

    span {
        font-weight: 700;
    }
`;

const Volume = styled.img`
    width: 25px;
    cursor: pointer;
`;

export default () => {

    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, value => value / 5);
    const y2 = useTransform(scrollY, value => value / 8);
    const y3 = useTransform(scrollY, value => value / 4);
    
    const playGuide = _ => {
        var audio = document.getElementById("guide"); 
        audio.play(); 
    }

    return (
        <section>
            <DotsContainer style={{y: y1}}>
                <Dots xCount="8" yCount="3" />
            </DotsContainer>
            <DiagonalLines style={{y: y2}}>
                <Lines count="12" />
            </DiagonalLines>
            <GreetingsWrapper>
                <IntroWrapper>
                    <div style={{position: "relative"}}>
                        <img src="/images/lines.svg" style={{position: "absolute", bottom: "-50px",  right: "10%"}} />
                    </div>
                    <RevealSlide delay="4.3" odelay="3.5">
                        <IntroMessage><span>Web developer</span> based in the Philippines</IntroMessage>
                    </RevealSlide>
                    <SlideUp delay="3.8">
                        <Message>Hey there, <strong>I'm Edrey</strong> 
                            <Wave
                                animate={{transform: [
                                    "rotate( 0.0deg)",
                                    "rotate(14.0deg)",
                                    "rotate(-8.0deg)",
                                    "rotate(14.0deg)",
                                    "rotate(-4.0deg)",
                                    "rotate(10.0deg)",
                                    "rotate( 0.0deg)",
                                    "rotate( 0.0deg)"
                                ]}} 
                                transition={{duration: 2.5, repeat: Infinity}}
                            >
                                &#x1f44b;
                            </Wave>
                        </Message>
                    </SlideUp>
                    <Pronounciation>
                        <span>Ed</span> · Ray <Volume onClick={playGuide} src="/images/volume.svg" title="Play" />
                        <audio id="guide">
                            <source src="edrey.mp3" type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </Pronounciation>
                </IntroWrapper>
            </GreetingsWrapper>
            <HDotsContainer style={{y: y3}}>
                <Dots yCount="8" xCount="3" />
            </HDotsContainer>
        </section>
    );
}