import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Grid from "../../common/Grid";
import Menu from "../../common/menu/Menu";
import { LeftPanel, RightPanel, Footer } from "../layout/Layout";

import Components from "./components";
import Projects from "../../assets/projects.json";

const DetailsBanner = styled(motion.div)`
    width: calc(100% - 16.666%);
    height: 100vh;
    top: 0;
    left: 16.666%;
    background-color: #ededed;
    position: absolute;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (max-width: 992px) {
        & {
            left: 8.333333333333333%;
            width: calc(100% - 8.333333333333333%);
        }
    }
`;

const Container = styled.section`
    width: 66.664%;
    float:left;
    position: relative;
    left: 16.666%;
    top: 100vh;

    @media (max-width: 992px) {
        & {
            left: 8.333333333333333%;
            width: 83.33333333333333%;
        }
    }

    section {
        margin-top: 130px;
        display:table;
        width: 100%;
    }
`;

const Content = styled(motion.div)`
    width: 70%;
    background-color: #f6f6f6;
    bottom: 0px;
    position: absolute;
    
    h1 {
        margin: 0px;
        padding: 30px 5px;
    }
`; 

const NextPrevContainer = styled.div`
    width: 100%;
    display: table;
`;

const Next = styled.div`
    float: right;
    width: 25%;
    display: table;
    align-items: center;
    background-color: #0400f9;
    border-radius: 8px;
    position: relative;

    @media (max-width: 768px) {
        width: 40%;
    }

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const Prev = styled.div`
    float: left;
    width: 25%;
    display: table;
    align-items: center;
    background-color: #0400f9;
    border-radius: 8px;
    position: relative;

    @media (max-width: 768px) {
        width: 40%;
    }

    @media (max-width: 576px) {
        width: 100%;
        margin-bottom: 50px;
    }
`;

const Thumbnail = styled(motion.div)`
    width: 100%;
    float: left;
    height: 80px;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    z-index: -1;
    top: 0%;
    transition: 0.8;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const NPContent = styled.div`
    padding: 20px;
    width: 100%;
    float: left;

    p, small {
        margin: 0px;
        color: #fff;
    }

    small {
        font-size: 15px;
    }

    p {
        font-weight: 700;
        color: #fff;
        font-size: 20px;
    }
`;

export default () => {
    const { type, slug } = useParams();
    const banner = "url(" + Projects[type][slug].banner + ")";

    /* Handling Menu */
    const [state, setState] = useState({visible:false});

    const handleMenuToggle = () => {
        setState({ visible: !state.visible});
    }
    const menuVisibleStyle = state.visible ? {position : "relative", zIndex : "-10"} : {}; 
    const allProjects = {...Projects.m, ...Projects.fun }

    // Get all the keys in the object
    var keys = Object.keys(allProjects);
    var index = keys.indexOf(slug);

    var prevProject = keys[index-1];
    var nextProject = keys[index+1];
    var next = allProjects[nextProject];
    var prev = allProjects[prevProject];

    document.title = 'Edrey Ching | ' + Projects[type][slug].name;


    const [isPrevHovered, setPrevHovered] = useState(false);
    const [isNextHovered, setNextHovered] = useState(false);

    return(
        <div>
          <Menu visible={state.visible} menuClicked={handleMenuToggle} />
          <LeftPanel style={menuVisibleStyle} menuClicked={handleMenuToggle} />
          <DetailsBanner animate={{ opacity:1 }} initial={{ opacity:0 }} style={{backgroundImage: banner}}>
              <Content 
                initial={{transform: "translateY(100%)"}}
                animate={{ transform: "translateY(0%)"}}
                transition={{ duration: 0.5, ease: [.52,.24,.39,.76] }} 
            >
                <h1>{Projects[type][slug].name}</h1>
              </Content>
          </DetailsBanner>
          <Container style={menuVisibleStyle}>
            <Components type={type} slug={slug} />
            <NextPrevContainer>  
            {
                prev && 
                <Link to={prev.slug ?? ''}>
                    <Prev
                        onMouseEnter={() => setPrevHovered(true)}
                        onMouseLeave={() => setPrevHovered(false)}
                    >
                        <Thumbnail animate={{y: isPrevHovered ? '-85%' : '0%'}}>
                            <img src={prev.image ?? prev.banner} />
                        </Thumbnail>
                        <NPContent>
                            <small>Previous Project</small>
                            <p>{ prev.name ?? '' }</p>
                        </NPContent>
                    </Prev>
                </Link>  
            }
            {
                next &&
                <Link to={next.slug ?? ''}>
                    <Next
                        onMouseEnter={() => setNextHovered(true)}
                        onMouseLeave={() => setNextHovered(false)}
                    >
                        <Thumbnail animate={{y: isNextHovered ? '-85%' : '0%'}}>
                            <img src={next.image ?? next.banner} />
                        </Thumbnail>
                        <NPContent>
                            <small>Next Project</small>
                            <p>{ next.name?? '' }</p>
                        </NPContent>
                    </Next>
                </Link>    
            }
            </NextPrevContainer>
            <Footer />
          </Container>
          <RightPanel style={menuVisibleStyle} />
          <Grid style={menuVisibleStyle} />
      </div>
    );
}