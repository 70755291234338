import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Tips from "../assets/tips.json";

const Item = styled(motion.div)`
    width: 16.666%;
    float: left;
    overflow: hidden;
    height: 100vh;

    @media (max-width: 992px) {
        &:nth-child(1) {
            display: none;
        }

        &:nth-child(2), &:nth-child(5) {
            width: 8.333333333333333%;
        }

        &:nth-child(3), &:nth-child(4) {
            width: 41.66666666666667%;
        }
    }
`;

const Transition = styled(motion.div)`
    width: 100%;
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    background: #fff;
`;

const Background = styled(motion.div)`
    width: 100%;
    background-color: #222222;
    height: 100%;
    transform: translateX(-100%);
`;

const Loading = styled(motion.div)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;

    @media (max-width: 992px) {
        height: 100vh;
    }

    h1 {
        margin: 0px;
        color: #fff;
        transform: translateY(100%);
        text-align: center;
    }

    p {
        transform: translateY(100%);
        margin: 0px;
        color: #fff;
        font-weight: 300;
        font-size: 14px;
    }
`;

const Content = styled(motion.div)`
    overflow: hidden;
`;

export default ({menuVisible}) => {

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
        setVisible(false);
        }, 3500);
    }, [3500]);
    
    if (!visible && !menuVisible) {
        document.body.style.overflow = "auto";
    } else {
        document.body.style.overflow = "hidden";
    }

    const random = Math.random()*Tips.length;
    
    return visible ? (
            <Transition className="transition" initial={{background: "#fff"}} animate={{background: "transparent"}} transition={{duration: .5, ease:[0.4, 0, 0, 1], delay: 1}}>
                <Item>
                    <Background
                        intial={{transform: "translateX(-100%)"}}
                        animate={{transform: [
                            "translateX(-100%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(100%)"]}}
                        transition={{duration: 3.5, ease:[0.4, 0, 0, 1]}}
                    />
                </Item>
                <Item>
                    <Background
                        intial={{transform: "translateX(-100%)"}}
                        animate={{transform: [
                            "translateX(-100%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(100%)"]}}
                        transition={{duration: 3.5, ease:[0.4, 0, 0, 1]}}
                    />
                </Item>
                <Item>
                    <Background
                        intial={{transform: "translateX(-100%)"}}
                        animate={{transform: [
                            "translateX(-100%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(100%)"]}}
                        transition={{duration: 3.5, ease:[0.4, 0, 0, 1]}}
                    />
                </Item>
                <Item>
                    <Background
                        intial={{transform: "translateX(-100%)"}}
                        animate={{transform: [
                            "translateX(-100%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(100%)"]}}
                        transition={{duration: 3.5, ease:[0.4, 0, 0, 1]}}
                    />
                </Item>
                <Item>
                    <Background
                        intial={{transform: "translateX(-100%)"}}
                        animate={{transform: [
                            "translateX(-100%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(100%)"]}}
                        transition={{duration: 3.5, ease:[0.4, 0, 0, 1]}}
                    />
                </Item>
                <Item>
                    <Background
                        intial={{transform: "translateX(-100%)"}}
                        animate={{transform: [
                            "translateX(-100%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(0%)",
                            "translateX(100%)"]}}
                        transition={{duration: 3.5, ease:[0.4, 0, 0, 1]}}
                    />
                </Item>
                <Loading>
                    <Content>
                        <motion.h1
                            initial={{translateY: "100%"}}
                            animate={{translateY: ["100%", "0%","0%","0%","100%","100%"]}}
                            transition={{duration: 3.5, ease:[0.4, 0, 0, 1], delay: .5}}
                        >
                            Loading. Please wait...
                        </motion.h1>
                    </Content>
                    <Content>
                        <motion.p 
                            initial={{translateY: "100%"}}
                            animate={{translateY: ["100%", "0%","0%","0%","100%","100%"]}}
                            transition={{duration: 3.8, ease:[0.4, 0, 0, 1], delay: .2}}
                        >
                            {Tips[Math.floor(random)].type}: {Tips[Math.floor(random)].description}
                        </motion.p>
                    </Content>
                </Loading>
            </Transition>
        ) : (
            <div></div>
        )
}