import React, { Component } from 'react';
import styled from "styled-components";

import CliCommands from './commands';

import FunctionCmd from './functions';
import ResponseCmd from './responses';

/* Components */
import DefaultResponse from './components/response/Default';
import Response from './components/response/Response';
import Command from './components/command/Command';

const CliWrapper = styled.section`
  background-color: #222222f2;
  color:#fff;
  min-height: 100vh;
  width:100%;
  max-width:100vw;
  padding: 10px;
  font-size: 14px;
  font-family: 'Roboto Mono', monospace;
`;

class Cli extends Component {
  
  constructor(props) {
    super(props);
    this.cmdRef = React.createRef();
    
    this.state = {
      history: [],
      responses : [],
      welcome : 'Hi! Welcome to CLI version of my portfolio, type --h to display all available commands.'
    };
  }
  
  handleClick = () => {
    this.cmdRef.current.focus();
  }
  
  onKeyUp = (event) => {

    if (event.target.value === '') {
      this.setState({ 
        latestCmd: this.state.history.length - 1,
      });
    }

    if (event.keyCode === 13) {
      const history = this.state.history;
      const newCmdIndex = history.length ?? + 1;
      const command = event.target.value;
      /* Remove the last item/empty string so we can append a new one with proper index */
      history.pop();

      /* Update the states */
      let today = new Date();
      this.setState({ 
        latestCmd: newCmdIndex,
        history: history.concat(
          [{
            'cmd' : event.target.value, 'time' : ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2)
          }],
          [{
            'cmd' : ''
          }]
        )
      });
      this.cmdRef.current.value = '';

      // This will handle all the commands
      this.handleCommand(command);
    }

    if (event.keyCode === 38) {
      event.preventDefault();
      this.setState({ 
        latestCmd: this.state.history[this.state.latestCmd - 1] === undefined ? this.state.latestCmd : this.state.latestCmd - 1,
      }, () => this.cmdRef.current.value = this.state.history[this.state.latestCmd] ? this.state.history[this.state.latestCmd].cmd : '');
    }

    if (event.keyCode === 40) {
      event.preventDefault();
      if (this.state.latestCmd === this.state.history.length) {
        this.cmdRef.current.value = '';
        return;
      }

      this.setState({ 
        latestCmd: this.state.history[this.state.latestCmd + 1] === undefined ? this.state.latestCmd : this.state.latestCmd + 1,
      }, () => this.cmdRef.current.value = this.state.history[this.state.latestCmd] ? this.state.history[this.state.latestCmd].cmd : '');
    }
  }

  handleCommand = (command) => {
    const responses = this.state.responses;
    const trimedCommand = command.split(" ")[0]; // this check only the first command and disregard all the options on the command

    // Check if command not exist
    if (!CliCommands[trimedCommand]) {
      this.setState({ 
        responses : responses.concat([
          {
            type : 'ERR',
            command : command,
            content : 'Command not found. type <i>-help</i> to know all available commands'
          }
        ]) 
      });
      return;
    }

    /* Check if the command is function or not */
    if (CliCommands[trimedCommand].type !== 'FUN') { // if not function update the state for rendering of the reponse
      const content = ResponseCmd(command);
      
      this.setState({ 
        responses : responses.concat([
          {
            type : content.type,
            command : command, 
            content : content.content 
          }
        ]) 
      });
    } else { // call the functional command for processing
      FunctionCmd(command, this);
    }
  }
  
  /* All commands that has response */
  renderResponse = (response, index) => {
    switch(response.type) {
      case 'RES':
        return <Response key={index} response={response.content} time={this.state.history[index].time} command={response.command} />
      default:
        return <DefaultResponse key={index} time={this.state.history[index].time} command={response.command}>{response.content}</DefaultResponse>
    }
  }
  
  scrollToBottom = () => {
    this.cmdRef.current.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {

    document.title = 'Edrey Ching | CLI';
    
    return (
      <CliWrapper onClick={this.handleClick}>
        <p style={{textAlign:"center", color:'#686868', margin:'0px', letterSpacing:'0.5px'}}>Terminal</p>
        <div style={{display:"block", width:"100%"}} >{this.state.welcome}</div>
        {
          this.state.responses.map((response, index) => 
            this.renderResponse(response, index)
          )
        }
        <Command ref={this.cmdComponent} cmdRef={this.cmdRef} handleClick={this.handleClick} onKeyUp={this.onKeyUp} />
        <audio id="guide">
          <source src="edrey.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </CliWrapper>
      );
    }
  }
  
  export default Cli;