import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';

/* Components */
import { RoundText } from "../../../common/typography/Text";
import { Dots } from "../../../common/Dots";
import { RevealSlide } from "../../../common/animation/Animation";

import Content from "../../../assets/content.json";

const SectionHeader = styled.div`
    display: flex;
    align-items: center;

    ${RoundText} {
        margin-right: 20px;
    }

    p {
        margin: 0px;
        font-weight: 600;
    }
`;

const Container = styled.div`
    width: 100%;
    display: table;
`;

const Half = styled.div`
    width: 50%;
    float: left;
    margin-top: 80px;

    @media (max-width: 768px) {
        width: 100%;
    }

    p {
        width: 80%;
        padding-left: 5px;
        font-size: 18px;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    &:nth-child(even) {
        margin-top: 160px;        
        
        @media (max-width: 768px) {
            margin-top: unset;
        }
    }
`;

export default () => {
    return (
        <section>
            <RevealSlide delay="3.5" odelay="3" > 
                <SectionHeader>
                    <RoundText>01</RoundText>
                    <p>Some introduction about me</p>        
                </SectionHeader>
            </RevealSlide>
            <Container>
                <Half>
                    <RevealSlide>
                        {ReactHtmlParser(Content.about.intro[0])}
                    </RevealSlide>
                </Half>
                <Half>
                    <RevealSlide>
                        {ReactHtmlParser(Content.about.intro[1])}
                    </RevealSlide>
                </Half>
            </Container>
            <Dots xCount="8" yCount="3" />
        </section>
    );
}