import React, { useState } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";

const CliContent = styled.div`
    color: #fff;
    width: 100vw;
    position: absolute;
    z-index: 1;
    height: 100%;
`;

const IntroText = styled.div`
    width: 25%;
    padding: 50px;

    @media (max-width: 1024px) {
        padding: 20px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const MainText = styled.h2`
    font-size: 120px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 20px;
    margin: 0px;

    @media (max-width: 1024px) {
        font-size: 80px;
        letter-spacing: 10px;
    }
`;

const SubText = styled.h3`
    font-weight: 500;
    margin: 0px;
    font-size: 18px;
    color: #fff;
    line-height: 30px;

    
    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 24px;
    }
`;

const StrokeText = styled.span`
    color: white;
    -webkit-text-fill-color: black; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: white;
    font-size: 300px;
    font-weight: 700;
    letter-spacing: 80px;
    position: absolute;
    bottom: 0;
    z-index: -6;
    opacity: 0.03;
    line-height: 1;
    cursor: default;
`;

const Proceed = styled.button`
    position: absolute;
    bottom: 50px;
    left: 50px;
    background-color: transparent;
    border: 1px solid #ededed;
    padding: 15px 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.5s;

    &&:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1024px) {
        bottom: 50px;
        left: 20px;
    }
`;

const Command = styled.div`
    color: #fff;
    font-size: 18px;
    position: absolute;
    opacity: 0.3;
`;

const CliContentComponent = ({handleClick}) => {
    return(
        <CliContent>
            <IntroText>
                <MainText>CLI</MainText>
                <SubText>Command Line Interface for the ones who love commands rather than buttons.</SubText>
            </IntroText>
            <StrokeText>CLI</StrokeText>
            <Proceed 
                onClick={handleClick}
            >Proceed to CLI</Proceed>
            
            <Command style={{top: "10%", right: "50%" }}>--h</Command>
            <Command style={{top: "20%", right: "40%" }}>switch</Command>
            <Command style={{top: "75%", right: "50%" }}>clear</Command>
            <Command style={{top: "15%", right: "30%" }}>about</Command>
            <Command style={{top: "15%", right: "25%" }}>works</Command>
            <Command style={{top: "15%", right: "55%" }}>works --fun</Command>
            <Command style={{top: "10%", right: "70%" }}>works --brands</Command>
            <Command style={{bottom: "10%", right: "25%" }}>about --stack</Command>
            <Command style={{bottom: "15%", right: "35%" }}>tf</Command>
            <Command style={{bottom: "25%", right: "35%" }}>sayedrey</Command>

        </CliContent>
    );
}

export default CliContentComponent;
