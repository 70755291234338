import React from "react";
import styled from "styled-components";

/* Components */
import { LargeHeading } from "../../../common/typography/Text";
import { RevealSlide, SlideUp } from "../../../common/animation/Animation";

const InfoContainer = styled.div`
    width: 50%;
    float: left;

    p {
        font-size: 18px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const MainText = styled(LargeHeading)`
    font-size: 35px;
    font-weight: 700;
    line-height: 47px;
    margin: 0px;
    span{   
        &:before {
            height: 20px;
            bottom: 7px;
        }
    }
`;

const ContactInfo = styled.div`
    margin-top: 50px;
`;

const Label = styled.span`
    font-size: 14px;
    font-weight: 300;
`;

const Number = styled.div`
    font-size: 18px;
    font-weight: 500;
`;

const Icon = styled.img`
    width: 20px;
    margin-right: 10px; 
`;

const IconContainer = styled.div`
    height: 20px;
`;

export default () => {
    return (
        <InfoContainer>
            <RevealSlide delay="4.3" odelay="3.5">
                <MainText><span>Don't be a stranger</span>, <br /> say hello.</MainText>
            </RevealSlide>
            <SlideUp delay="4.3">
                <p>I'd love to help you start your project. I'm just one email away. I'm excited to hear from you!</p>
            </SlideUp>
            <ContactInfo>
                <Label>Email at</Label>
                <p>chingedrey@gmail.com</p>
            </ContactInfo>
            <ContactInfo>
                <Label>Chat me</Label>
                <Number>+63 968 296 9094</Number>
                <IconContainer>
                    <Icon src="/images/viber.svg" title="viber" alt="viber" />
                    <Icon src="/images/whatsapp.svg" title="whatsapp" alt="whatsapp" />
                </IconContainer>
            </ContactInfo>
        </InfoContainer>       
    );
}