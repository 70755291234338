import React, {Component} from 'react';
import styled from "styled-components";

const SideTime = styled.div`
    color: #767676;
    font-size: 12px;
    width: 50px;
    float: left;
`;

class Time extends Component {
    render() {
        
        return (
            <SideTime>
                {this.props.time}
            </SideTime>
        );
    }
}



export default Time;
