import React from "react";
import styled from "styled-components";

/* Components */
import { Dots } from "../../common/Dots";
import { BackgroundText } from "../../common/typography/Text";
import { RevealSlide, SlideUp } from "../../common/animation/Animation";
import Tips from "../../assets/tips.json";

const MainText = styled.h2`
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 50px;
`;

const MainContent = styled.div`
    margin: 0px;
`;

const Crafted = styled.p`
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
`;

const LeftDots = styled.div`
    position: absolute;
    top: 65px;
    z-index: -1;
`;

const RightDots = styled.div`
    position: absolute;
    top: 65px;
    z-index: -1;
    right: 0px;
`;

const FooterContainer = styled.footer`
    position: relative;
    margin-top: 100px;
`;

const Email = styled.div`
    margin:60px 0px;
    width: 100%;
    text-align: center;
`;

const TipsContainer = styled.small`
    font-size: 10px;
    font-size: 10px;
    display: block;
    width: 100%;
    text-align: center;
`;

export default () => {

    const random = Math.random()*Tips.length;

    return (
        <FooterContainer>
            <LeftDots><Dots xCount="4" yCount="8" /></LeftDots>
            <RightDots><Dots xCount="6" yCount="4" /></RightDots>
            <MainContent>
                <BackgroundText text="Let's work together" />
                <RevealSlide delay="1.1" odelay="0">
                    <MainText>Let's work together</MainText>
                </RevealSlide>
                <SlideUp delay=".5">
                    <Email>chingedrey@gmail.com</Email>
                </SlideUp>
            </MainContent>
            <hr />
            <SlideUp delay=".9">
                <Crafted>Crafted with ❤️</Crafted>
                <TipsContainer>{Tips[Math.floor(random)].type}: {Tips[Math.floor(random)].description}</TipsContainer>
            </SlideUp>
        </FooterContainer>
    );
} 