import React, { useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

/* Components */
import { SmallHeading, BackgroundText } from "../../../common/typography/Text";
import Projects from "../../../assets/projects.json";
import Content from "../../../assets/content.json";

const FunContainer = styled.section`
    position: relative;
    min-height: 500px;
`;

const ProjectSub = styled.div`
    width: 50%;
    float: left;
    margin-top: 20px;

    p {
        width: 80%;
    }
`;

const SectionTitle = styled(SmallHeading)`
    margin: 0px;
`; 

const LeftText = styled.div`
    transform: rotateZ(270deg);
    left: -492px;
    position: absolute;
    top: 0px;
    width: 100%;
    display: table;
    z-index: -1;

    h2 {
        color: #305dff2b;
    }
`;

const ProjectList = styled.div`
    width: calc(100% - 100px);
    display: table;
    position: relative;
    left: 100px;
    
    @media (max-width: 1280px) {
        width: 100%;
        left: 0px;
    }
`;

const Project = styled.div`
    border-radius: 15px;
    margin: 0;
    margin-bottom: 10px;
    padding: 15px;
    min-height: 200px;
    background: ${props => props.catchBg};
    background: ${props => props.background};
    width: 100%;    
    
`;

const ProjectContainer = styled(motion.div)`
    float: left;
    width: 25%;
    padding: 0px 8px;

    @media (max-width: 920px) {
        width: 50%;
    }

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display:table;
`;

const Image = styled.div`
    background-color: #ffffff;
    border-radius: 12px;
    width: 40%;
    float: left;
    height: 120px;
    overflow: hidden;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transform: scale(1.2);
        transition: 0.5s; 
    }
`;

const FunContent = styled.div`
    width: 60%;
    float: left;
    color: #fff;

    h4 {
        color: inherit;
        margin-bottom: 5px;
        line-height: 1.5;
        font-size: 18px;
        color: ${props => props.color}
    }
    
    small {
        font-size: 11px;
        font-weight: 300;
    }
`;

const Logo = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
`;

const SubDetailsContainer = styled.div`
    width: 100%;
    display: table;
    padding-top: 10px;
`;

const SubDetails = styled.div`
    display: flex;
    width: 50%;
    float:left;
`;

const SubDetailsContent = styled.div`
    padding: 5px;

    p, small {
        color: #fff;
        margin: 0px;
    }

    p {
        font-size: 12px;
        line-height: 1px;
        font-weight: 700;
        color: ${props => props.color}
    }

    small {
        font-size: 9px;
    }
`;

const FunProject = ({ item, key }) => {

    const [isHovered, setHovered] = useState(false);

    return (
        <ProjectContainer 
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Project 
                key={key+item.name}
                catchBg={item.bg.catchBackground}
                background={item.bg.background}
                style={item.style}
            >
                <Wrapper>
                    <FunContent color={item.mainColor}>
                        <Logo src={item.logo} />
                        <h4>{item.name}</h4>
                        <small>{item.platform}</small>
                    </FunContent>
                    <Image> 
                        <motion.img animate={{scale: isHovered ? '1' : '1.2'}} src={item.banner}/>
                    </Image>
                </Wrapper>
                <SubDetailsContainer>
                    <SubDetails>
                        
                        <SubDetailsContent color={item.secondaryColor}>
                            <p>{item.since}</p>
                            <small style={{fontWeight: 300, color: "#fff"}}>Built Since</small>
                        </SubDetailsContent>
                    </SubDetails>
                    <SubDetails>
                        
                        <SubDetailsContent color={item.secondaryColor}>
                            <p>{item.industry}</p>
                            <small style={{fontWeight: 300, color: "#fff"}}>Industry</small>
                        </SubDetailsContent>
                    </SubDetails>
                </SubDetailsContainer>
            </Project>
        </ProjectContainer>
    );
}

export default () => {

    return (
        <FunContainer>
            <LeftText>
                <BackgroundText text="Fun" />
            </LeftText>
            <div style={{display: "table", width: "100%"}} >
                <SectionTitle>Fun Projects</SectionTitle>
                <ProjectSub>
                    { ReactHtmlParser(Content.works.fun) }
                </ProjectSub>
                <ProjectList>
                    {
                        Object.entries(Projects.fun).map(([key, item]) => {
                            return (
                                <Link to={item.slug}>
                                    <FunProject item={item} key={key} />
                                </Link>
                            );
                        })
                    }
                </ProjectList>
            </div>
           
        </FunContainer>
    );
}