import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';

import { MediumHeading } from "../../../common/typography/Text";
import List from "./List";

const Container = styled.div`
    width: 100%;
    background-color: ${props => props.background ?? "#1042FF"};
    color: ${props => props.color ?? "#fff"};
    display: flex;
    padding: 50px;
    min-height: 300px;
    align-items:center;

    @media (max-width: 768px) {
        display: table;
    }
`;

const Half = styled.div`
    width: 50%;
    float: left;
    color: #fff;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SectionTitle = styled(MediumHeading)`
    margin: 0px;
    color: ${props => props.color};

`;
const TitleContainer = styled.div``;

export default ({background, color, items, bg}) => {

    let count = 0;

    return(
        <Container background={background} color={color}>
            <img width="758" height="190" src={bg} style={{position: "absolute", zIndex: 0, opacity: "0.05"}} />
            <Half>
                <TitleContainer>
                    <SectionTitle color={color}>
                        What's next for this project
                    </SectionTitle>
                </TitleContainer>
            </Half>
            <Half>

                {items.map((item, index) => {
                    count++;
                    return <List item={item} count={count} rounded={false} />;
                })}
            </Half>
        </Container>
    );
}