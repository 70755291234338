import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

/* Components */
import Grid from "../../common/Grid";
import Menu from "../../common/menu/Menu";
import {LeftPanel, RightPanel, Footer} from "../layout/Layout";
import Transition from "../../common/Transition";
import Info from "./components/Info";
import Form from "./components/Form";
import { Dots } from "../../common/Dots";


const Container = styled.section`
    width: 66.664%;
    float:left;
    position: relative;
    left: 16.666%;

    section {
        margin-top: 130px;
        display:table;
        width: 100%;
    }

    @media (max-width: 992px) {
        & {
            left: 8.333333333333333%;
            width: 83.33333333333333%;
        }
    }
`;

const DotsContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 30px;

    @media (max-width: 992px) {
        & {
            width: 100%;
            left: 30%
        }
    }
`;

export default () => {
    /* Handling Menu */
    const [state, setState] = useState({visible:false});

    const handleMenuToggle = () => {
        setState({ visible: !state.visible});
    }
    const menuVisibleStyle = state.visible ? {position : "relative", zIndex : "-10"} : {}; 

    document.title = 'Edrey Ching | About';

    return (
        <motion.div exit={{opacity : 0}}>
            <Menu visible={state.visible} menuClicked={handleMenuToggle} />
            <LeftPanel style={menuVisibleStyle} menuClicked={handleMenuToggle} />
            <Container style={menuVisibleStyle}>
                <section>
                    <DotsContainer>
                        <Dots xCount="8" yCount="3" />
                    </DotsContainer>
                    <Info />
                    <Form />
                </section>
                <Footer />
            </Container>
            <RightPanel style={menuVisibleStyle} />
            <Grid style={menuVisibleStyle} />
            <Transition menuVisible={state.visible} />
        </motion.div>
    );
}