import react from 'react';
import styled from "styled-components";

const DotsContainer = styled.div`
    position: relative;
    line-height: 16px;
`;

const Dot = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1042FF;
    float: left;
    margin: 0px 5px;
`;

const Dots = ({xCount,yCount}) => {
    const dot = [];

    for (let i = 0; i < yCount; i++) {
        for (let index = 0; index < xCount; index++) {
            dot.push(<Dot key={"dots" + i + index} />);
        }   
        dot.push(<br key={"dotsbr" + i} />);
    }

    return (
        <DotsContainer>
            {dot}
        </DotsContainer>
    );
}


const SmallDots = styled.span`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #D2D2D2;
    float: right;
    margin: 15px 15px;
`;

const SmDots = ({xCount,yCount}) => {
    const dot = [];

    for (let i = 0; i < yCount; i++) {
        for (let index = 0; index < xCount; index++) {
            dot.push(<SmallDots key={"smdots" + i + index} />);
        }   
        dot.push(<br key={"smbr" + i} />);
    }

    return (
        <DotsContainer>
            {dot}
        </DotsContainer>
    );
}

const Circle = styled.div`
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: 50%;
    background-color: ${props => props.color};
`;

export {Dots, SmDots, Circle};