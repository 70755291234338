import React from "react";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from "framer-motion";

/* Components */
import { LargeHeading } from "../../../common/typography/Text";
import { Dots } from "../../../common/Dots";
import { RevealSlide, SlideUp } from "../../../common/animation/Animation";

const IntroWrapper = styled.div`
    position: relative;
    margin: 150px 0px;
`;

const IntroMessage = styled(motion.div)`
    font-size: 18px;
    font-weight: 600;

    span{
        position: relative;
        
        &:before {
            height: 15px;
            width: 90%;
            background-color: #1042FF;
            content: '';
            position: absolute;
            display: block;
            z-index: -1;
            bottom: -2px;
            padding: 0px 7px;
            left: 0px;
        }
    }
`;

const Message = styled(LargeHeading)`
    margin-top: 100px;

    @media (max-width: 768px) {
        margin-top: 50px;
    }

    strong {
        color : #1042FF;
    }
`;

const DontsContainer = styled(motion.div)`
    position: absolute;
    top: -130px;
    left: 30%;
`;


const DontsVContainer = styled(motion.div)`
    position: absolute;
    bottom: -150px;
    right: 0;
`;

export default () => {

    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, value => value / 5);
    const y2 = useTransform(scrollY, value => value / 8);
    const y3 = useTransform(scrollY, value => value / 4);

    return (
        <IntroWrapper>
            <DontsContainer style={{y: y1}}>
                <Dots xCount="8" yCount="3" />
            </DontsContainer>
            <motion.div style={{y: y2}}>
                <RevealSlide delay="4.3" odelay="3.5">
                    <IntroMessage><span>Since 2016</span>, I've been doing projects for my clients</IntroMessage>
                </RevealSlide>
            </motion.div>
            <SlideUp delay="3.8">
                <Message>I do <strong>lots</strong> of stuff.</Message>
            </SlideUp>
            <div style={{position: "relative"}}>
                <img src="/images/tri.svg" style={{position: "absolute", top: "-50px",  right: "20%"}} />
            </div>
            <DontsVContainer style={{y: y3}}>
                <Dots xCount="3" yCount="8" />
            </DontsVContainer>
        </IntroWrapper>
    );
}