import React from "react";
import styled from "styled-components";

/* Components */
import { SmallHeading } from "../../../common/typography/Text";
import List from "./List";


const Container = styled.div`
    width: 100%;
    display: table;
`;

const Half = styled.div`
    width: 50%;
    float: left;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SectionTitle = styled(SmallHeading)`
    text-align: ${props => props.titleAlign};
`;

export default ({title, items, titleAlign}) => {
    let count = 0;

    return (
        <Container>
            <SectionTitle titleAlign={titleAlign}>{title}</SectionTitle>
            <Half>
                {items[0].map((item, index) => {
                    count++;
                    return <List item={item} count={count} color="#ededed" />;
                })}
            </Half>
            <Half>
                {items[1].map((item, index) => {
                    count++;
                    return <List item={item} count={count} color="#ededed" />;
                })}
            </Half>
        </Container>
    );
}