import React from "react";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';

import Logos from "../../../assets/brands.json";
import Content from "../../../assets/content.json";

/* Components */
import { SmallHeading, BackgroundText } from "../../../common/typography/Text";

const BrandContainer = styled.section`
    position: relative;
    min-height: 500px;
`;

const ProjectSub = styled.div`
    width: 50%;
    float: left;
    margin-top: 20px;

    p {
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SectionTitle = styled(SmallHeading)`
    margin: 0px;
`; 

const LeftText = styled.div`
    transform: rotateZ(270deg);
    left: -492px;
    position: absolute;
    top: 170px;
    width: 100%;
    display: table;
    z-index: -1;

    h2 {
        color: #305dff2b;
    }
`;

const BrandContent = styled.div`
    width: 75%;
    float: right;

    @media (max-width: 960px) {
        width: 100%;
    }
`;

const Brand = styled.div`
    display: inline-block;
    width: 200px;
    min-height: 50px;
    margin: 10px 0px;

    @media (max-width: 768px) {
        display: block;
        margin: 30px auto;
    }

    img {
        width: 80%;
        display: block;
        margin: auto;
        filter: grayscale(100%);
    }
`;

export default () => {
    return (
        <BrandContainer>
            <LeftText>
                <BackgroundText text="Brands" />
            </LeftText>
            <SectionTitle>Brands I've worked with</SectionTitle>
            <ProjectSub>
                {ReactHtmlParser(Content.works.brands)}
            </ProjectSub>
            <BrandContent>
                
                {
                    Logos.map(((logo, index) =>
                    <Brand key={`${logo.name}${index}`}>
                        <img src={logo.path} title={logo.name} />
                    </Brand>
                    ))
                }
            </BrandContent>
        </BrandContainer>
    );
}