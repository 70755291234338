import contents from "../../gui/assets/content.json";
import skills from "../../gui/assets/skills.json";
import projects from "../../gui/assets/projects.json";
import brands from "../../gui/assets/brands.json";
import Tips from "../../gui/assets/tips.json";
import allCommands from "../commands";

const Help = _ => {

    let content = '<table class="cli_ub">';
    Object.entries(allCommands).map(([key, item]) => {
        
        content += '<tr>';
        content += '<td>' + key + '</td><td>' + item.description + '</td>';
        content += '<tr>';
        if (item.options) {
            Object.entries(item.options).map(([k, v]) => {
                content += '<tr>';
                content += '<td class="sub">' + k + '</td><td>' + v.description + '</td>';
                content += '<tr>';
                return;
            });
        }

        return
    });

    content += '</table>'

    return {
        type: "RES", 
        content: content
    };
}

const About = command => {

    const options = command.split(" ");
    options.shift();
    
    if (command === 'about') {
        return {
            type: "RES", 
            content: contents.about.intro[0] + 
            "<h3>Years of experience</h3>" + contents.about.exp.yrs.content +
            "<h3>Industries</h3>" + contents.about.exp.industries.content +
            "<h3>Third party/Providers</h3>" + contents.about.exp.projects.content
        };
    }

    let content = '';
    let type = 'ERR';

    options.forEach(option => {
        
        type = 'RES';
        
        switch (option) {
            case '--stack':
                content += '<h3>Tools and Experties</h3>';
                skills.forEach(skill => {
                    content += skill.name + '<br />';
                });
                break;
        
            default:
                content = 'Command/Option invalid, please type -h to display all available commands';
                type = 'ERR';
                break;
        }

    });

    return { type: type , content: content }

}

const Works = command => {
    const options = command.split(" ");
    options.shift();
    
    let content = '';
    let type = 'ERR';

    let fun = '';
    let main = '';

    Object.entries(projects.m).map(([key, item]) => {
        return main += '<tr><td width="500px">'+ item.name +'</td><td>Client Project</td><td>'+ item.platform +'</td><td><a href="'+item.slug+'" target="_blank">Link</a></td></tr>';
    });
    
    projects.old.forEach(project => {
        main += '<tr><td width="500px">'+ project.name +'</td><td>Client Project</td><td>'+ project.platform +'</td><td>--</td></tr>';
    });
    
    Object.entries(projects.fun).map(([key, item]) => {
        return fun += '<tr><td width="500px">'+ item.name +'</td><td>Fun Project</td><td>'+ item.platform +'</td><td><a href="'+item.slug+'" target="_blank">Link</a></td></tr>';
    });


    if (command === 'works') {
        content = '<table class="cli_tbl"><thead><th>Project Name</th><th>Type</th><th>Platform</th><th>GUI Link</th></thead><tbody>';
        content += main + fun;
        content += '</tbody></table>';
        content += '<hr />';
        type = 'RES';
    }

    const availableOptions = allCommands.works.options;
   
    options.forEach(option => {
        type = 'RES';
        switch (option) {
            case '--fun':
                content += '<h3>Fun Projects</h3>';
                content += contents.works.fun;
                content += '<table class="cli_tbl"><thead><th>Project Name</th><th>Type</th><th>Platform</th><th>GUI Link</th></thead><tbody>';
                content += fun;
                content += '</tbody></table>';
                content += '<hr />';
                break;

            case '--brands':
                let brandNames = '';
                brands.forEach(item => {
                    brandNames += item.name + '<br />';
                });

                content += '<h3>Brands/Thirdparty I already worked with</h3>';
                content += contents.works.brands;
                content += brandNames;
                content += '<hr />';
                break;
        
            default:
                content = 'Command/Option invalid, please type -h to display all available commands';
                type = 'ERR';
                break;
        }

    });

    return {
        type: type, 
        content: content
    };
}

const Contact = _ => {
    let content = '';
    content += '<p><strong>Email:</strong> chingedrey@gmail.com</p>';
    content += '<p><strong>Phone/Viber/Whatsapp:</strong> +63 968 296 9094</p>';
    
    return { type: "RES" , content: content }
}


const TF = _ => {
    const random = Math.random()*Tips.length;
    return {type : "RES", content: Tips[Math.floor(random)].type +' : '+ Tips[Math.floor(random)].description };
}

export { Help, About, Works, Contact, TF }